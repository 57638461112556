.search-based-availability {
  strong {
    font-weight: bold;
  }

  .subtitle {
    font-size: 16px;
  }
}

.search-based-availability__also-in {
  padding-top: 10px;
}