.vintage-alert {
  background-color: #F6FAFB;
  border: 1px solid #EBEBEB;
  padding: 20px;

  p {
    text-align: justify !important;
    color: #999999;
    font-weight: normal;
    font-size: small;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}