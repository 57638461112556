/*
  ##Screen = B/w max-width 768px
*/
@media (max-width: 768px) {
  header {
    .navbar.top {
      ul.navbar-nav {
        margin: 0 -15px;
        > li {
          display: inline-block;
          padding-left: 5px !important;
          padding-right: 5px !important;
          a {
            font-size: 10px !important;
          }
          span.vline {
            margin-left: 15px !important;
            margin-right: 7px !important;
          }
        }
      }
      .navbar-header {
        position: relative;
        top: 7px;
        .navbar-brand {
          span.logo {
            width: 120px !important;
          }
        }
      }

    }

    .header-middle {
      ul.menu {
        li {
          margin-right: 7px !important;
          .recently-viewed {
            a.recently-viewed__link {
              font-size: 10px !important;
            }
          }
          .dropdown {
            button {
              font-size: 10px !important;
            }
          }
        }
      }

      ul.menu.right {
        .dropdown {
          button {
            span {
              font-size: 10px !important;
            }

            .header-cart-dropdown__counter {
              font-size: 10px !important;
              top: -4px !important;
            }
          }
        }
      }

      .vintage-autocomplete__select-wrapper {
        width: 60px !important;
        vertical-align: middle !important;
        select {
          font-size: 10px !important;
        }
      }

      form.header-search-form {
        .form-group {
          display: inline-block !important;
          vertical-align: middle !important;
          margin-bottom: 0 !important;
          input {
            max-width: 130px !important;
            font-size: 10px !important;
          }
        }
      }

      span.vline {
        margin-left: 5px !important;
        margin-right: 5px !important;
      }

      .bottom {
        ul {
          padding: 0 !important;
          li {
            line-height: 25px !important;
            padding-left: 8px !important;
            padding-right: 14px !important;
            a {
              font-size: 10px !important;
            }
          }
        }
      }
    }
  }
}

/*
  ##Screen = B/w min-width 768px to 992px
*/
@media (min-width: 768px) and (max-width: 992px) {
  header {
    .header-middle {
      ul.menu {
        li {
          margin-right: 7px !important;
          .recently-viewed {
            a.recently-viewed__link {
              font-size: 13px !important;
            }
          }

          .dropdown {
            button {
              font-size: 13px !important;
            }
          }
        }
      }

      ul.menu.right {
        li {
          a {
            font-size: 13px !important;
          }
        }
        .dropdown {
          button {
            span {
              font-size: 13px !important;
            }
          }
        }
      }

      span.vline {
        margin-left: 5px !important;
        margin-right: 5px !important;
      }
    }
  }
}

/*
  ##Screen = B/w max-width 768px
*/
@media (max-width: 768px) {
  header {
    .header-middle {
      ul.menu.right {
        li {
          a {
            font-size: 10px !important;
          }
        }
        .dropdown {
          button {
            span {
              font-size: 10px !important;
            }
          }
        }
      }

      span.vline {
        margin-left: 5px !important;
        margin-right: 5px !important;
      }
    }
  }
}
