.category-breadcrumb {
  .breadcrumb {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;

    li {
      font-weight: 400;
      color: #4E5A61;

      a {
        color: #143C96;
      }
    }
  }
}