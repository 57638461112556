.career-opportunities {
  ul.career-opportunities__ways-apply {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 30px;
    span.career-opportunities__ways-apply__email {
      color: #143C96;
    }
  }
  strong {
    font-weight: 900;
    font-size: 16px;
  }
  p:last-child {
    a {
      display: inline-block;
      span.btn-custom {
        margin-left: 0;
      }
    }
  }
}