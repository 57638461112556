form#advanced-search-form{
  select[multiple] {
    height: auto;
    overflow-y: hidden;
    padding: 0;
    option {
      padding: 5px 10px;
    }
    &.select-platform {
      overflow-y: auto;
    }
  }
  .align-right{
    text-align: right;
  }
}