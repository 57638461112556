@media only screen and (min-width: 768px) and (max-width: 900px) {
  .bottom__section {
    .nav__item_container {
      padding-top: 5px;
      padding-bottom: 5px;

      .nav__item {
        padding-left: 6px !important;
        padding-right: 10px !important;
      }

      a {
        font-size: 10px !important;
      }
    }
  }
}