.wrapper.orders {
  padding-bottom: 30px;

  .order {
    position: relative;
    border-bottom: 1px solid #EBEBEB;
    padding-bottom: 95px;
    margin-bottom: 30px;

    .options {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 30px 0;
      text-align: right;

      a {
        text-decoration: none;
        margin-right: 5px;

        span.btn-custom {
          background: #FFFFFF;
          border: 2px solid #143C96;
          border-radius: 100px;
          font-weight: 700;
          font-size: 14px;
          color: #143C96;
          display: inline-block;
          line-height: 32px;
          height: 35px;
          padding-left: 35px;
          padding-right: 35px;
        }

        span.btn-custom.cancel {
          border-color: #B9B9B6;
          background: #B9B9B6;
          box-shadow: 0 1px 0 0 #9A9A93;
          color: #FFFFFF;
        }

        span.tracking {
          border-color: #DCE84C;
          background: #DCE84C;
          box-shadow: 0 1px 0 0 #A9B811;
          color: #143C96;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .order:last-child {
    border-bottom: none;
    margin-bottom: none;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 20px;

    span {
      font-weight: 700;
    }
  }

  .status {
    margin-bottom: 20px;

    span {
      display: inline-block;
      vertical-align: middle;
      line-height: 24px;
    }

    span.circle {
      height: 14px;
      width: 14px;
      border-radius: 14px;
      background-color: #EEE;
      margin-right: 5px;
    }

    span.text {
      font-weight: 700;
      font-size: 14px;
    }
  }

  .status.confirmed {
    span.circle {
      background-color: #1B8816;
    }

    span.text {
      color: #1B8816;
    }
  }

  .status.processing {
    span.circle {
      background-color: #F4C400;
    }

    span.text {
      color: #F4C400;
    }
  }

  .status.awaiting-payment {
    span.circle {
      background-color: #FF7E00;
    }

    span.text {
      color: #FF7E00;
    }
  }

  .status.issues {
    span.circle {
      background-color: #AE1A20;
    }

    span.text {
      color: #AE1A20;
    }
  }

  // TODO missing refunded and partially refunded statuses on here
  .items-section {
    margin-bottom: 20px;
  }

  .item {
    .photo {
      border-radius: 2px;
      display: inline-block;
      vertical-align: middle;
      width: 62px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      img {
        width: 100%;
      }
    }

    .info {
      display: inline-block;
      vertical-align: middle;

      p {
        font-weight: 400;
        font-size: 14px;
        color: #4E5A61;
        margin-bottom: 5px;
        span {
          font-weight: 400;
        }
        span.qty {
          margin-left: 20px;
        }
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  .payment-info {
    h3 {
      font-weight: 700;
      font-size: 13px;
      color: #4E5A61;
      margin: 0;
      margin-bottom: 10px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      color: #4E5A61;
      line-height: 27px;

      span {
        display: inline-block;
        vertical-align: middle;
        font-weight: 400;
        font-size: 14px;
        color: #4E5A61;
        line-height: 27px;
      }

      span.icon {
        border: none;
        border-radius: 0;
        background-color: transparent;
        height: 27px;
        width: 41px;
        margin-right: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

  .shipping-info {
    h3 {
      font-weight: 700;
      font-size: 13px;
      color: #4E5A61;
      margin: 0;
      margin-bottom: 10px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      color: #4E5A61;
      line-height: 27px;
    }
  }

  .summary {
    h3 {
      font-weight: 700;
      font-size: 13px;
      color: #4E5A61;
      margin: 0;
      margin-bottom: 10px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      color: #4E5A61;
      line-height: 27px;
    }

    table {
      tbody {
        tr {
          td {
            font-weight: 400;
            font-size: 14px;
            color: #4E5A61;
            line-height: 27px;
            border: none;
            padding: 0;
          }
        }

        tr.order-totals__section--total {
          td {
            font-weight: 700;
            font-size: 14px;
            color: #4E5A61;
            line-height: 27px;
          }
        }
      }
    }
  }
}