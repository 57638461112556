.single-credit-card {
  position: relative;
  padding: 10px;
  border: 1px solid #eee;

  &.single-credit-card--billing {
    border: none;

    span.icon {
      top: 35px !important;
    }
  }

  .single-credit-card__full-name {
    margin-left: 50px !important;
  }

  .single-credit-card__billing-address {
    margin-left: 50px !important;
  }

  .single-credit-card__info {
    float: none;
    margin-left: 50px !important;

    span {
      display: inline-block;
      vertical-align: middle;

      &.icon {
        position: absolute;
        top: 23px;
        left: 10px;
        border: none;
        border-radius: 0px;
        height: 27px;
        width: 41px;
        margin-right: 10px;
        background-size: contain;
        background: transparent no-repeat center center;
      }
    }
  }

  .single-credit-card__edit-button {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #4E5A61;
  }
}