@media (max-width: 768px) {
  .bottom__section {
    .nav__item_container {
      margin: 0;
      padding: 0;
      text-align: center;

      .nav__item {
        line-height: 25px !important;
        padding-left: 6px !important;
        padding-right: 8px !important;

        a {
          font-size: 10px !important;
        }
      }
    }
  }
}