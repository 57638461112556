.credit-card-info {
  span.icon {
    &.visa {
      background-image: url('../img/credit-card.png');
    }
    &.amex {
      background-image: url('../img/credit-card-amex.png');
    }
    &.mastercard {
      background-image: url('../img/credit-card-mastercard.png');
    }
    &.discover {
      background-image: url('../img/credit-card-discover.png');
    }
    &.paypal {
      border: none;
      border-radius: 0px;
      background-color: transparent;
      height: 27px;
      width: 41px;
      margin-right: 10px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url('../img/paypal-logo.png');
    }
  }
}