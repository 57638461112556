.trust-elements {
  display: flex;
  flex-direction: column;
}

.trust-elements--as-row {
  padding: 1.5rem 0;
  flex-direction: row;
  justify-content: space-evenly;
}

.trust-elements--as-column {
  flex-direction: column;
}

.trust-elements__item {

}