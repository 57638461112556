#header-cart-dropdown {
  .header-cart-dropdown__icon {
    position: relative;
    padding-right: 0;
    transition: padding-right ease-out 200ms;

    &.header-cart-dropdown__icon--with-counter {
      padding-right: $header-cart-counter-radius + 6px;

      .header-cart-dropdown__counter {
        opacity: 1;
      }
    }
  }

  .header-cart-dropdown__counter {
    padding: 2px;
    border-radius: 100%;
    background-color: darken($header-cart-items-count-background-color, 15%);
    color: white;
    width: $header-cart-counter-radius;
    height: $header-cart-counter-radius;
    display: inline-block;
    font-size: round($header-cart-counter-radius * 0.6);
    line-height: $header-cart-counter-radius - 3px; // eye calculated
    margin-left: round($header-cart-counter-radius * 0.25);
    margin-right: round($header-cart-counter-radius * 0.1);
    font-weight: bold;
    top: -1px;
    opacity: 0;
    transition: opacity ease-out 100ms;
    position: absolute;
    right: 0;
  }
}
