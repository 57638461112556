.products-filters-bar {
  label {
    font-size: 13px !important;
  }
}

.products-filter {
  position: relative;
  text-align: center;

  $separators-width: 6%;

  &.products-filter--separator      { width: ($separators-width / 3);  }  // There are 3 separators, it means = 6%
  &.products-filter--sort-by        { width: (100% - $separators-width) * 0.25; }
  &.products-filter--view-as        { width: (100% - $separators-width) * 0.17; }
  &.products-filter--items-per-page { width: (100% - $separators-width) * 0.23; }
  &.products-filter--pagination     { width: (100% - $separators-width) * 0.35; }

  label {
    margin: 0 5px 0 0;
    color: #4E5A61;
  }

  .form-control {
    height: $filters-bar-height;
    padding-left: 2px;
    padding-right: 2px;
  }

  button, .btn {
    height: $filters-bar-height;

    &:focus, &:active {
      outline: none;
    }
  }

  &.products-filter--separator {
    height: $filters-bar-height;

    &:after {
      content: "";
      width: 1px;
      background-color: #EBEBEB;
      position: absolute;
      left: 50%;
      height: 70%;
      top: 15%;
    }
  }

  &.products-filter--sort-by {
    select {
      max-width: 64%;
    }
  }

  &.products-filter--view-as {
    button {
      $gutter: ($filters-bar-height - $view-as-buttons-radius) / 2;

      max-width: $view-as-buttons-radius;
      height: $view-as-buttons-radius;
      margin: $gutter 0;
      text-align: center;
      color: #DEE4F2;
      padding: 0;
      font-size: 20px;

      &:last-child {
        margin-left: $gutter;
      }

      &.btn--active {
        color: #143C96;
      }
    }
  }

  &.products-filter--items-per-page {
    label:last-child {
      margin-left: 5px;
      margin-right: 0;
      font-weight: normal;
    }
  }

  &.products-filter--pagination {
    label {
      font-weight: normal;

      strong {
        font-weight: bold;
      }
    }

    .products-pagination {
      display: inline-block;
      border: 1px solid #EBEBEB;

      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }

      input {
        height: $filters-bar-height - 6px !important;
        width: $filters-bar-height;
        padding: 0;
        text-align: center;
        border-color: #EBEBEB;
        border-width: 1px;
        font-weight: bold;
        color: #4E5A61;
      }

      label {
        margin: 0;
        position: relative;
        bottom: -2px;
      }

      button {
        border: none;
        background-color: transparent;
        &.btn {
          padding: 6px 6px;
        }
      }
    }
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px){
  .products-filter {
    text-align: left;
  }

  .products-filter--sort-by  {
    .form-control{
      width: 100%;
    }
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (max-width: 768px){
  .products-filters-bar{
    .products-filter {
      label {
        font-size: 11px !important;
      }
      &.products-filter--sort-by select {
        max-width: 59% !important;
      }
      &.products-filter--view-as button {
        font-size: 12px !important;
        height: 15px !important;
      }
      &.products-filter--items-per-page {
        select{
          width: auto !important;
        }
      }
    }

    .form-inline {
      .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
      }
      .form-control {
        display: inline-block !important;
      }
    }

    .products-filter.products-filter--pagination .products-pagination input {
      display: inline-block;
    }
  }
}


