.small-product-card {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.small-product-card__image {
  width: 62px;

  img {
    width: 100%;
  }
}

.small-product-card__name {
  padding-left: 15px;

  p {
    font-weight: bold;
    margin-bottom: 10px;
  }
}