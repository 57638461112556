@media (max-width: 992px) {
  .header-account-dropdown-button__text {
    font-size: 13px;

    .header-account-dropdown-item__link {
      .icon {
        font-size: 13px;
      }
    }
  }
}