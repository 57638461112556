@media (max-width: 768px) {
  .nav__main-header_container {
    ul {
      margin: 0 -15px;

      > .nav__item {
        display: inline-block;
        padding-left: 5px !important;
        padding-right: 5px !important;

        a {
          font-size: 10px !important;

          span.nav__vline {
            margin-left: 15px !important;
            margin-right: 7px !important;
          }
        }
      }
    }
  }
}