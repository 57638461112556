.product-details {
  .text-unavailable {
    color: red !important;
  }

  .wishlist-heart-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
  }

  p.section-title {
    font-weight: bold !important;
    margin-bottom: 0;
    margin-top: 30px;
  }

  .product-image {
    width: 70%;
    margin: 30px auto auto;

    .product-type {
      width: 190px;
    }
  }

  .price__container {
    display: flex;
    align-items: center;
    width: 100%;

    &.product--has-stock {
      .product-price-selector {
        width: 30%;
      }
    }

    .unit-price__container {
      margin-top: 32px;
    }

    .price__label-container {
      margin-left: 25px;
      width: 60%;
    }
  }
}

section.product-details .wrapper .right .top {
  overflow-x: hidden;  // for the line separators below
}

.product-shipping-date {
  padding: 10px 0 10px;

  p {
    font-size: 14px !important;

    &:first-child {
      margin-bottom: 10px !important;
      font-weight: 700 !important;
    }

    &:last-child {
      i {
        font-style: italic !important;
        font-weight: 700 !important;
        color: $red !important;
      }
    }
  }
}

.product-trust-elements {
  position: relative;
  margin: 1.3rem 0 1.3rem;

  &:after,
  &:before {
    content: "";
    display: block;
    width: 120%;
    height: 1px;
    position: absolute;
    left: -10%;
    background-color: #EBEBEB;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }
}

.product-details__loading {
  padding: 50px 0;
}

.products-by-platform {
  margin-bottom: 30px;

  p {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    color: #4E5A61 !important;
    margin-bottom: 20px !important;
  }

  a {
    padding: 10px;
    border: 1px solid lighten(black, 80%);
    margin-right: 20px;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      border-color: lighten(black, 50%);
    }
  }
}

.single-product-store-availability {
  padding: 15px 0;
}

.single-product-store-availability__available {
  button {
    display: inline;
    border: none;
    background: transparent;
    box-shadow: none;
    padding: 0;
    margin: 0;
    color: #A9B811;

    &:active,
    &:focus {
      outline: none;
    }
  }
}

.single-product-store-availability__unavailable {
  color: red;
}

.call-us-to-reserve {
  background-color: #F3FDFC;
  color: #143D98;
  display: inline-block;
  padding: 10px 20px;

  strong {
    font-weight: bold;
  }
}

