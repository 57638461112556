.subscribe-to-newsletter {
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  background-image: url($newsletter_subscription_background_img_url);
  padding-top: $newsletter_subscription_padding_container;
  padding-bottom: $newsletter_subscription_padding_container;
  font-family: 'Roboto', sans-serif;

  .subscribe-to-newsletter__title {
    text-align: center;
    font-weight: 700;
    font-size: $newsletter_subscription_title_font_size;
    color: $newsletter_subscription_primary_color;
    line-height: 37px;
    margin-bottom: $newsletter_subscription_text_margin_bottom;
  }

  .subscribe-to-newsletter__subtitle {
    text-align: center;
    font-weight: 400;
    font-size: $newsletter_subscription_subttitle_font_size;
    color: $newsletter_subscription_subtitle_color;
    line-height: 24px;
    margin-bottom: $newsletter_subscription_text_margin_bottom;
  }

  .subscribe-to-newsletter__button_container {
    margin-bottom: 0;
    text-align: center;

    .subscribe-to-newsletter__button {
      display: inline-block;
      cursor: pointer;
      font-weight: 700;
      font-size: 14px;
      padding: 0 40px;
      line-height: 50px;
      height: 50px;
      margin-bottom: $newsletter_subscription_text_margin_bottom;
      margin-left: 10px;
      width: 100%;
      max-width: 340px;
      background: $newsletter_subscription_primary_color;
      box-shadow: 0 1px 0 0 $newsletter_subscription_button_box_shadow_color;
      border-radius: 100px;
      color: $newsletter_subscription_button_text_color;
      text-shadow: 0 1px 0 $newsletter_subscription_button_text_shadow_color;
    }
  }
}