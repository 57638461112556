.un-styled {
  background-color: transparent;
  border: none;
}

.btn-custom {
  display: inline-block;
  cursor: pointer;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 700;
  font-size: 14px;
  color: #143C96;
  border: none;
  background: #DCE84C;
  box-shadow: 0 1px 0 0 #A9B811;
  border-radius: 100px;
  padding: 0px 40px;
  line-height: 50px;
  height: 50px;
  margin-bottom: 20px;
  margin-left: 10px;

  span {
    display: inline-block;
    vertical-align: middle;
  }

  span.icon {
    margin-right: 5px;
  }

  &.btn-custom--gray {
    background: #EDEDED;
    box-shadow: 0 1px 0 0 #AAAAAA;
  }

  &.btn-custom--only-icon {
    padding: 0 30px;
    height: 30px;
    line-height: 30px;
    margin-bottom: 5px;
  }
}

.btn-disabled {
  opacity: 0.7;
  pointer-events: none;
}