.price-table {
  thead {
    tr {
      td {

      }
    }
  }

  tbody {
    tr {
      &:first-child {
        td {
          border-top: none;
        }
      }

      td {
        text-align: center;
        color: #4E5A61;
        font-weight: bold;

        &:first-child {
          text-align: left;
        }
      }
    }
  }
}