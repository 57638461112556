.recently-viewed {
  position: relative;
  display: inline-block;

  .recently-viewed__link {
    color: white;
  }

  .recently-viewed__dropdown {
    position: absolute;
    top: 110%;
    left: -14px;
    z-index: 1000;
    display: none;
    &.recently-viewed__dropdown--is-open {
      display: block;
    }

    .recently-viewed__dropdown__wrapper {
      border: 1px solid #EBEBEB;
      position: relative;
      height: auto;
      background-color: white;
      border-radius: 2px;
      padding: 23px 37px;
      .recently-viewed__dropdown__wrapper__triangle {
        position: absolute;
        top: -5px;
        left: 25px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent white transparent;
      }

      a {
        text-decoration: none;
        span[class^="icon-arrow"] {
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          z-index: 1000;
          font-size: 27px;
          color: #4E5A61;
          opacity: 0.3;
          transition: opacity ease-out 200ms;
          &.recently-viewed__dropdown__wrapper__arrow-left {
            left: 50px;
            &.recently-viewed__dropdown__wrapper__arrow--disabled {
              opacity: 0.1;
            }
          }

          &.recently-viewed__dropdown__wrapper__arrow-right {
            right: 25px;
            &.recently-viewed__dropdown__wrapper__arrow--disabled {
              opacity: 0.1;
            }
          }
        }
        span[class^="icon-arrow"]:hover {
          opacity: 0.5;
        }
      }

      .recently-viewed__dropdown__wrapper__header {
        margin-bottom: 15px;
        .recently-viewed__dropdown__wrapper__header__title {
          color: #6d7e85;
          font-size: 15px;
          font-weight: 900;
        }

        .recently-viewed__dropdown__wrapper__header__button-all {
          height: 37px;
          line-height: 37px;
          margin-bottom: 0;
          float: right;
          span.btn-custom {
            padding: 0 20px;
            line-height: 37px;
            height: 37px;
            margin-bottom: 20px;
            margin-right: 0;
          }
        }
      }

      .recently-viewed__dropdown__wrapper__body {
        position: relative;
        overflow: hidden;
        text-align: center;
        ul.recently-viewed__dropdown__wrapper__body__products-list {
          list-style: none;
          white-space: nowrap;
          display: inline-block;
          margin: 0 auto;
          overflow-x: hidden;
          li.recently-viewed__dropdown__wrapper__body__products-list__item {
            display: inline-block;
            white-space: nowrap;
            margin-right: 30px;
            width: 20%;

            .product-price__text-amount {
              padding-top: 5px;
            }
          }
        }
      }
    }
  }
}

/*
  ##Screen = B/w 1200px
*/
@media (min-width: 1200px) {
  .recently-viewed {
    .recently-viewed__dropdown {
      .recently-viewed__dropdown__wrapper {
        .recently-viewed__dropdown__wrapper__body {
          ul.recently-viewed__dropdown__wrapper__body__products-list {
            li.recently-viewed__dropdown__wrapper__body__products-list__item {
              display: inline-block;
              white-space: nowrap;
              margin-right: 5px;
              .product-item {
                text-align: center;
                display: inline-block;
                overflow-x: hidden;
                width: 200px;
                .photo {
                  position: relative;
                  display: inline-block;
                  img {
                    width: 82px;
                    height: 118px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/*
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1200px) {
  .recently-viewed {
    .recently-viewed__dropdown {
      .recently-viewed__dropdown__wrapper {
        .recently-viewed__dropdown__wrapper__body {
          ul.recently-viewed__dropdown__wrapper__body__products-list {
            li.recently-viewed__dropdown__wrapper__body__products-list__item {
              display: inline-block;
              white-space: nowrap;
              margin-right: 10px;
              .product-item {
                text-align: center;
                display: inline-block;
                overflow-x: hidden;
                width: 115px;
                .photo {
                  position: relative;
                  display: inline-block;
                  img {
                    width: 82px;
                    height: 118px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


/*
  ##Screen = B/w 768px to 1024px
*/
@media (max-width: 768px) {
  .recently-viewed {
    a.recently-viewed__link {
      font-size: 10px !important;
    }
    .recently-viewed__dropdown {
      &.container {
        width: 600px;
      }
      .recently-viewed__dropdown__wrapper {
        a {
          span[class^="icon-arrow"].recently-viewed__dropdown__wrapper__arrow-right{
            right: -8px;
          }

          span[class^="icon-arrow"].recently-viewed__dropdown__wrapper__arrow-left{
            left: 20px;
          }
        }

        .recently-viewed__dropdown__wrapper__body {
          ul.recently-viewed__dropdown__wrapper__body__products-list {
            li.recently-viewed__dropdown__wrapper__body__products-list__item {
              display: inline-block;
              white-space: nowrap;
              margin-right: 10px;
              .product-item {
                text-align: center;
                display: inline-block;
                overflow-x: hidden;
                width: 90px;
                .photo {
                  position: relative;
                  display: inline-block;
                  img {
                    width: 62px;
                    height: 90px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}