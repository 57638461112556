@media (max-width: 768px) {
  .header-search-form {
    .form-group {
        display: inline-block !important;
        vertical-align: middle !important;
        margin-bottom: 0 !important;
      input {
        max-width: 130px !important;
        font-size: 10px !important;
      }

      .vintage-autocomplete__select-wrapper {
        width: 65px;

        select {
          font-size: 10px !important;
        }
      }
    }
  }
}
