.order-item {
  margin-bottom: 20px;
}

.order-item__photo {
  display: block;
  float: left;
  width: $order-item-photo-width;

  img {
    width: 100%;
  }
}

.order-item__info {
  float: none;
  padding-left: $order-item-photo-width + 15px;

  p {
    margin-bottom: 0 !important;
  }
}
