$icon-radius: 32px;

.select-address {
  .select-address__icon {
    width: $icon-radius;
    float: left;

    .icon-Pin {
      font-size: $icon-radius;
      color: #4E5A61;
    }
  }

  .select-address__info {
    float: none;
    display: block;
    margin-left: $icon-radius + 5px;

    .bold {
      font-weight: bold;
    }
  }
}