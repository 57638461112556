.featured-ad__section {
  .featured-ad__box {
    position: relative;
    background-image: linear-gradient(-46deg, #143C96 0%, #5367FA 100%);
    border-radius: 2px;
    padding: 60px 30px;
    overflow-y: hidden;

    .box__ad_background {
      display: block;
      position: absolute;
      top: -30px;
      bottom: -30px;
      left: 30px;
      width: 440px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      background-image: url('../img/Gift-card-banner@2x.png');
    }

    .featured-ad__line {
      display: block;
      height: 3px;
      background-color: #FFF;
      width: 38px;
      margin: 25px auto;
    }

    .box__ad_title {
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 28px;
      color: #FFF;
      line-height: 37px;
    }

    .box__ad_link, .box__ad_content-text {
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #FFF;
      line-height: 24px;
      margin: 0;

      a {
        .box__ad_action_button {
          display: inline-block;
          margin: 0 auto;
          height: 50px;
          width: auto;
          padding: 0 30px;
          background: #DCE84C;
          box-shadow: 0 2px 0 0 #A9B811;
          border-radius: 2px;
          line-height: 50px;
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 14px;
          color: #143C96;
        }
      }
    }
  }
}