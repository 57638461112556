div.navigation-tabs {
  .tab-content {
    .tab-pane {
      padding-top: 30px;

      p {
        font-family: 'Roboto', sans-serif !important;
        font-weight: 400;
        font-size: 14px;
        color: #4E5A61;
        line-height: 24px;
        margin-bottom: 20px;
      }

      table {
        border-radius: 2px;

        tr {
          th {
            font-family: 'Roboto', sans-serif !important;
            font-weight: 700;
            font-size: 14px;
            color: #4E5A61;
            line-height: 19px;
            background-color: #F6FAFB;
            padding: 15px 20px;
          }

          td {
            font-family: 'Roboto', sans-serif !important;
            font-weight: 400;
            font-size: 14px;
            color: #4E5A61;
            line-height: 19px;
            padding: 15px 20px;
          }
        }
      }
    }
  }
}