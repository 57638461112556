.expired-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  min-height: 30rem;
  margin-top: -98px;  // header height
  margin-bottom: -61px;  // footer height

  button {
    display: inline-block;
    cursor: pointer;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 700;
    font-size: 14px;
    color: #143C96;
    border: none;
    background: #DCE84C;
    box-shadow: 0 1px 0 0 #A9B811;
    border-radius: 100px;
    padding: 0 40px;
    line-height: 50px;
    height: 50px;
    margin-bottom: 20px;
    margin-left: 10px;
  }
}