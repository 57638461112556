.credit-card-list {

  .credit-card-list__card {

    &.payment-method__credit-card--details {
      border: 1px solid #eee;
      position: relative;
      margin-bottom: 10px;

      .credit-card-list__card--input {
        margin: inherit;
        display: inline-block;

        input[type="radio"] {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 25px;
        }
      }

      .single-credit-card {
        display: inline-block;
        border: none;
      }
    }

    &.credit-card-list__selected {

    }
  }
}
