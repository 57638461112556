.featured-products__container {
  padding: 30px 0;

  .featured-products__tabs {
    .featured-products__tab {
      padding: 0;

      a {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: #4E5A61;
        margin: 0;
        padding: 12px 15px;
        text-align: center;
      }

      &.active {
        a {
          color: #143C96;
        }
      }
    }
  }

  .featured-products__tabs-content {
    border: 1px solid #DDD;
    border-top: none;
    padding: 30px 30px 0;

    .feature-products__grid {
      padding: 20px;
      text-align: center;

      .product-item {
        margin-bottom: 30px;
        border: 1px solid #eee;

        .photo {
          img {
            margin: 0 auto;
            width: 132px;
          }
        }
        .info {
          text-align: center;
          h6 {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 16px;
            color: #4E5A61;
            margin: 13px 0;
            line-height: 21px;
          }
          p {
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 13px;
            color: #4E5A61;
            line-height: 26px;
            text-align: center;
            span {
              display: inline-block;
            }
            span.cond {
              margin-left: 5px;
            }
            span.price {
              font-size: 20px;
            }
            span.price.used {
              color: #143C96;
            }
            span.price.new {
              color: #A9B811;
            }
            span.discount {
              font-size: 14px;
              font-weight: bold;
              color: #1B8816;
            }
          }
          .ova {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 13px;
            color: #4E5A61;
            line-height: 18px;
            text-align: center;
            strong {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
