.featured-category {
  padding: 10px 10px;
  display: inline-block;
  width: (100% / 5);

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  .featured-category__icon-container {
    display: block;
    width: $featured_category_icon_container_dimension;
    height: $featured_category_icon_container_dimension;
    box-shadow: 2px 2px 4px 0 rgba(153, 153, 153, 0.50);
    border-radius: 10px;
    margin: 20px auto;
    text-align: center;
    background-color: $featured_category_icon_background_color;
    line-height: 212px;

    .featured-category__icon {
      line-height: 212px;
      font-size: $featured_category_icon_size;
      color: $featured_category_icon_color;
      display: inline-block;
      height: 90px;
      width: 90px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      vertical-align: middle;

      &.movies {
        background-image: url($featured_category_movies_img_url);
      }

      &.games {
        background-image: url($featured_category_games_img_url);
      }

      &.music {
        background-image: url($featured_category_music_img_url);
      }

      &.toys {
        background-image: url($featured_category_toys_img_url);
      }

      &.posters {
        background-image: url($featured_category_posters_img_url);
      }
    }
  }

  .featured-category__title {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: $featured_category_title_color;
    line-height: 26px;
  }

  &.background--is-red {
    .featured-category__icon-container {
      background-color: $featured_category_red_color;
    }
  }

  &.background--is-green {
    .featured-category__icon-container {
      background-color: $featured_category_green_color;
    }
  }

  &.background--is-yellow {
    .featured-category__icon-container {
      background-color: $featured_category_yellow_color;
    }
  }

  &.background--is-pink {
    .featured-category__icon-container {
      background-color: $featured_category_pink_color;
    }
  }

  &.background--is-navy {
    .featured-category__icon-container {
      background-color: $featured_category_navy_color;
    }
  }

  .featured-category__title {
    color: $featured_category_gray_color;
  }

}
