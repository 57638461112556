.shopping-cart-totals {
  margin-bottom: 0;

  tbody {

    tr {

      td {
        border-top: none;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #4E5A61;
        -webkit-font-smoothing: antialiased;
        padding: 0;
        padding-bottom: 20px;
      }

      td.right {
        text-align: right;
      }

      &.shopping-cart-totals__discount, &.shipping-cart-totals__promotion {

        td {
          font-weight: 700 !important;
        }
      }
    }

    tr.total {
      border-top: 1px solid #EBEBEB;

      td {
        font-weight: 700;
        font-size: 18px !important;
        padding-top: 20px;
        padding-bottom: 0;
      }
    }
  }
}

.shopping-cart-totals--header {
  tbody {
    tr.total {
      border-top: none;

      td {
        padding-top: 0;
        font-size: 14px !important;

        &:first-child {
          color: #4E5A61;
        }
      }
    }
  }
}