.plain-invoice {
  padding: 40px 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  color: #4E5A61;
}

.plain-invoice__logo {
  margin-bottom: 40px;
}

.table {
  tbody, tbody tr, tbody tr td {
    border-color: #EEEEEE !important;
  }

  .plain-invoice__items, .plain-invoice__totals {
    border-top-width: 1px;

    tr {
      td {
        border-top: none;
        border-bottom: none;
      }
    }
  }

  .plain-invoice__totals {
    > tr {
      > td {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.plain-invoice__footer {
  margin-top: 100px;
  border-top: 1px solid #EEEEEE;
  padding-top: 10px;
}
