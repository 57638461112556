.sub-header__container {
  background-image: linear-gradient(-46deg, #143C96 0%, #5367FA 100%);

  .top__section {
    padding-top: 9px;
    padding-bottom: 9px;

    .sub-header__menu {
      display: inline-block;
      margin: 0;

      &.top__right-menu {
        float: right;
        text-align: right;
        display: inline-flex;

        li {
          margin: 0;
          line-height: 35px;

          span.menu_vline {
            margin-left: 25px;
            margin-right: 25px;
            border-right: 1px solid #3C5FAE;
            height: 12px;
            width: 1px;
          }

          a {
            span {
              display: inline-block;
              vertical-align: middle;

              &.menu_vline {
                margin-right: 0;
              }
            }
          }
        }

        .dropdown {
          margin: 0;

          button {
            span {
              padding-left: 20px;
            }
            span.caret {
              padding-left: 0;
            }
          }
        }
      }

      .menu__item {
        line-height: 36px;
        display: inline-block;
        margin-right: 60px;

        a {
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 14px;
          color: #FFF;
          line-height: 36px;

          span {
            vertical-align: middle;
            display: inline-block;

            &.icon {
              font-size: 8px;
            }
          }
        }
      }

      .dropdown {
        display: inline-block;

        button {
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 14px;
          color: #FFF;
          background: none;
          border: none;
          box-shadow: none;
          padding: 0;
          line-height: 36px;

          span {
            padding-left: 0;
            font-family: 'Roboto', sans-serif !important;
            font-weight: 400;
            font-size: 14px;
            color: #FFF;

            &:before {
              font-family: 'icomoon';
              position: absolute;
              top: 50%;
              left: 0;
              margin-top: -7px;
              font-size: 14px;
              color: rgba(255, 255, 255, 0.4);
            }
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}