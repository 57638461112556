.verify-email-alert {
  background-color: #DCEFF3;
  background-image: linear-gradient(135deg, #F6FAFB 0%, #DAEEF3 100%);
  border-radius: 2px;
  padding: 20px 30px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    width: 92px;
    height: 92px;
    border-radius: 100%;
    position: absolute;
    left: -42px;
    top: -16px;
    background-color: #DCEFF3;
  }

  img {
    position: relative;
    top: 4px;
    margin-right: 15px;
  }

  span {
    color: #143C96;
    font-size: 16px;
  }

  button {
    color: #143C96;
    font-size: 16px;
    background-color: transparent;
    border: none;
    font-weight: bold;
    text-decoration: underline;
  }
}