.store-accordion {
  margin-bottom: 10px;
  background-color: #F6FAFB;
  padding: 15px;
}

.store-accordion--is-open {
  .store-accordion__header {
    i {
      transform: rotate(180deg);
    }
  }
}

.store-accordion__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div:first-child {
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 15px;
  }

  p {
    margin-bottom: 0;
    color: #4E5A61;
  }

  strong {
    font-weight: bold;
  }

  i {
    transition: all 200ms ease-out;
    justify-self: flex-end;
    color: #4E5A61;
  }
}

.store-accordion-more-info {
  padding-top: 10px;

  p:first-child {
    margin-bottom: 10px;
  }
  .ship-limit-message{
    font-weight: bold;
    text-decoration: underline;
  }
}