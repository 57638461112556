.filters {
  .wrap {
    .panel-group {
      border: 0;
      margin-bottom: 0;

      .panel {
        box-shadow: none;
        border: 0;
        padding: 0;

        .panel-heading {
          border: inherit;
          padding: 0;
          background-color: inherit;
          width: 100%;

          a {
            i {
              float: right;
              color: gray;
            }
          }
        }

        .panel-body {
          border: inherit;
          padding: 0;

          li.item {
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 10px;
            padding-right: 0;
          }
        }
      }
    }

    .item {
      a {
        display: block;
      }

      .panel-title {
        a {
          i.fa {
            transform: rotate(180deg);
            transition: all ease-out 100ms;
          }

          &.collapsed {
            i.fa {
              transform: rotate(0deg);
            }
          }
        }
      }
    }
  }

  .panel-group {
    border: 0;
    margin-bottom: 0;
    .panel-title {

      &.active {
        a {
          color: #143C96;
        }
      }

      a {
        i.fa {
          transform: rotate(180deg);
          transition: all ease-out 100ms;
        }

        &.collapsed {
          i.fa {
            transform: rotate(0deg);
          }
        }
      }

    }

    .panel {
      box-shadow: none;
      border: 0;

      .panel-group__item {
        padding-left: 0;
        padding-right: 0;
        vertical-align: center;

        .form-field {
          display: inline;
        }

        p {
          display: inline-block !important;
          width: 80%;
          padding-left: 5px;
          label {
            &.item_platform {
              font-weight: 400;
            }
          }
        }
      }

      .panel-heading {
        padding: 10px 20px;
        border-top: 1px solid #EBEBEB;
        border-bottom: 1px solid #EBEBEB;
        background-color: white;
        h6 {
          margin: 0px;
          font-family: 'Roboto', sans-serif !important;
          font-weight: 700;
          font-size: 14px;
          color: #4E5A61;
          line-height: 19px;
          -webkit-font-smoothing: antialiased;
          background-color: inherit;

          a {
            display: block;
            i {
              float: right;
              color: gray;
            }
          }
        }
      }

      .panel-body {
        border: inherit;
      }
    }

    .panel-collapse--platform {
      .panel-body {
        height: 220px;
        overflow-y: auto;
        overflow-x: hidden;
      }

      ::-webkit-scrollbar {
        width: 6px;
      }

      ::-webkit-scrollbar-track {
        background-color: white;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #E9EEEF;
      }
    }
  }
}