.products-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.products-grid__product {
  padding: 35px 15px 0;  // 35px in top is the height of the product icon component
  width: (100% / 3);
  max-width: (100% / 3);
  margin-bottom: 30px;
}