.cart-item {
  border-bottom: 1px solid #EBEBEB;
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;

  .photo {
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;
    width: $cart-item-photo-width;

    img {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .info {
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px;

    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #4E5A61;
      margin-bottom: 0;
      line-height: 24px;

      &.info__details {
        line-height: $cart-item-info-height;
      }

      .pre-order-label,
      .sold-out-label {
        background-color: #C9D0E0;
        color: #143C96;
        font-weight: bold;
        text-transform: uppercase;
        border-radius: 3px;
        font-size: 13px;
        padding: 2px 5px;
        margin-left: 10px;
      }

      .sold-out-label {
        background-color: #EA4E53;
        color: white;
      }

      span {
        display: inline-block;

        &.item-condition {
          margin-left: 5px;
          font-weight: 700 !important;
          font-size: 13px;
          line-height: $cart-item-info-height;
          text-transform: uppercase;
          color: #4E5A61;
        }

        &.item-price {
          font-family: 'Roboto', sans-serif;
          font-weight: 700 !important;
          font-size: 18px;

          &.item-price--new {
            color: #143C96;
          }

          &.item-price--used {
            color: #143C96;
          }
        }

        &.item-promo {

        }

        .item-promo__label {
          margin: 0 5px 0 10px;
        }

        .item-promo__description {
          font-size: 12px;
          color: #4E5A61;
        }

        &.quality-guaranteed {
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 13px;
          color: #1B8816;
        }

        &.q-icon {
          font-size: 14px;
          margin-right: 5px;
          color: #1B8816;
        }
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .info__release-date {
    color: $red !important;
    font-weight: bold !important;
    font-size: 14px !important;
    margin-bottom: 5px !important;
  }

  .right {
    height: $cart-item-quantity-height;
    line-height: $cart-item-quantity-height;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: $cart-item-quantity-height / -2;

    .input {
      display: inline-block;
      vertical-align: middle;
      line-height: 35px;

      label {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: #4E5A61;
        margin: 0;
        margin-right: 20px;
        -webkit-font-smoothing: antialiased;
      }

      input[type="number"] {
        height: 35px;
        line-height: 35px;
        width: 120px;
        border-radius: 100px;
        border: 1px solid #EBEBEB;
        padding: 0 20px;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 18px;
        color: #4E5A61;
        text-align: center;
      }
    }

    .add-to-wish-list {
      display: inline;
      margin: 0 5px 0 0;

      a {
        color: #92A9B5;
        font-weight: 900;
        text-decoration: none;
        padding: 2px 10px;
        border-right: 2px solid #EBEBEB;

        span.icon:first-child {
          margin-right: 10px;
        }
      }
    }

    .options {
      display: inline-block;
      vertical-align: middle;
      line-height: 35px;

      a {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        line-height: 35px;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: #A9B811;
        -webkit-font-smoothing: antialiased;
        margin-left: 10px;

        span {
          display: inline-block;
          vertical-align: middle;
          font-size: 14px;
          line-height: 35px;
        }

        span.icon-plus {
          font-size: 7px;
        }

        span.icon-rubbish {
          font-size: 18px;
          color: #92A9B5;
        }
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
  }

  &.cart-item--in-header,
  &.cart-item--in-checkout {
    padding: 10px;
    border-bottom: 1px solid #EBEBEB;

    &:hover {
      background-color: #F6FAFB;
    }

    .photo {
      display: block;
      float: left;
    }

    .info {
      display: block;
      float: none;
      padding-left: 80px;
      padding-top: 0;
    }

    .info__name {
      text-align: start;
    }

    .info__details {
      padding-top: 10px;
      text-align: start;

      .item-quantity {
        margin-left: 5px;
        margin-right: 15px;
        color: #4E5A61;
        font-weight: 700 !important;
      }

      .item-condition {
        margin-right: 7px;
        text-transform: uppercase;
      }

      .item-price {
        font-size: 20px;
      }

      .item-price--new {
        color: #A0AF01;
      }

      .item-price--used {
        color: #212C90;
      }

      .item-promo {
        display: block;
        margin-top: 10px;
      }

      .item-promo__label {
        margin-left: 0;
      }

      .item-promo__description {

      }
    }

    .cart-item__delete-button {
      position: absolute;
      right: 10px; // same as padding of parent
      top: 50%;
      margin-top: $cart-item-header-delete-button-radius / -2;
      background-color: #677889 !important;
      z-index: 1000;
      text-align: center;
      border-radius: 100%;

      i {
        display: block;
        color: white;
        width: $cart-item-header-delete-button-radius;
        height: $cart-item-header-delete-button-radius;
        line-height: $cart-item-header-delete-button-radius;
        text-transform: uppercase;
        font-size: round($cart-item-header-delete-button-radius * 0.6);
      }
    }
  }
}
