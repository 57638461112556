// Colors

$featured_category_red_color: #DC5944;
$featured_category_green_color: #B5C420;
$featured_category_yellow_color: #FBB52B;
$featured_category_pink_color: #E35476;
$featured_category_navy_color: #2F3847;
$featured_category_gray_color: #4E5A61;

$featured_category_icon_background_color: #EEE;
$featured_category_icon_color: #FFF;
$featured_category_icon_container_dimension_sm_down: 160px;
$featured_category_icon_size: 86px;
$featured_category_icon_size_sm_down: 80px;

$featured_category_title_color: #333;

// Urls

$featured_category_movies_img_url: '../img/movies-bg.png';
$featured_category_games_img_url: '../img/games-bg.png';
$featured_category_toys_img_url: '../img/toys-bg.png';
$featured_category_posters_img_url: '../img/posters-bg.png';
$featured_category_music_img_url: '../img/music-bg.png';

// Dimensions

$featured_category_icon_container_dimension: 200px;
