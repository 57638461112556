$facebook_icon_background: #38599A;
$twitter_icon_background: #12A2F3;

$footer_top_section_background_color: #F6FAFB;
$footer_bottom_section_background_color_1: #143C96;
$footer_bottom_section_background-color_2: #5367FA;

$footer_top_section_text_color: #4E5A61;
$footer_bottom_section_text_color: #FFF;


