.store-info-card {
  margin-bottom: 30px;
}

.store-info-card__name {
  font-weight: bold !important;
  margin-bottom: 10px !important;

  small {
    font-weight: normal !important;
  }
}

p.store-info-card__address {
  margin-bottom: 10px !important;
}

.store-info-card__call-us {
  strong {
    font-weight: bold;
  }
  .ship-limit-message{
    font-weight: bold;
    text-decoration: underline;
  }
}