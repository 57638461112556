.product-image__container {
  .product-image__link {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background-color: #F6FAFB;

    img {
      padding: 15px;
      position: relative;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
