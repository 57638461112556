.wishlist-heart-btn {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 100%;
  border: none;
  background-color: white;
  color: #C9D0E0;
  font-size: 18px;
  box-shadow: 2px 4px 8px 0 rgba(97, 113, 121, 0.15);
  transition: color ease-out 100ms;

  &:hover {
    color: #143C96;
  }
}

.wishlist-heart-btn--active {
  background-color: #143C96;
  color: white;

  &:hover {
    color: #C9D0E0;
  }
}