div.gift-cards-disclaimer {
  background-image: linear-gradient(-144deg, #A779DA 10%, #5C69F6 100%);
  border-radius: 2px;
  padding: 5px 0;
  margin-bottom: 40px;
  position: relative;

  &:after,
  &:before {
    content: "";
    height: 100%;
    width: 50px;
    position: absolute;
    top: 0;
    background-repeat: no-repeat;
    background-size: auto 80%;
    background-position: center center;
    z-index: 100;
  }

  &:before {
    left: 0;
    background-image: url("../img/left-stars.png");
  }

  &:after {
    right: 0;
    background-image: url("../img/right-stars.png");
  }

  h2 {
    margin: 0;
    padding: 0;
    text-align: center;
    color: white;
  }
}

.container {
  .gift-cards-publicity {
    position: relative;
    height: $gift-cards-height;
    border: 1px solid #EBEBEB;
    padding: 40px 30px;
    border-radius: $border-radius;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../img/gift-background.jpg");
    margin-bottom: 30px;
    .gift-cards-publicity__content {
      text-align: center;
      h1 {
        font-size: $font-size-h1;
        color: #4E5A61;
        margin-bottom: 5px;
      }

      h2 {
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        font-size: 25px;
        color: #4E5A61;
        line-height: 37px;
        margin: 2px;
      }
    }
  }

  .gift-cards-item {
    position: relative;
    height: $gift-cards-height;
    border: 1px solid #EBEBEB;
    padding: 20px;
    border-radius: $border-radius;
    text-align: center;
    .gift-cards-item__header {
      position: relative;
      text-align: center;
      margin-bottom: 20px;
      span.gift-cards-item__header__holder {
        display: inline-block;
        width: 76px;
        height: 41px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("../img/card-holder.png");
      }

      span.gift-cards-item__header__logo {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        width: 42px;
        height: 41px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("../img/circle-logo.png");
      }
    }

    .gift-cards-item__content {
      margin-bottom: 20px;
      p {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0;
      }

      h1 {
        font-size: $font-size-h1;
        color: #143C96;
        margin-bottom: 0;
      }
    }

    .gift-cards-item__price {
      position: relative;
      height: 170px;
      padding: 15px 15px 0 15px;
      border-radius: $border-radius;
      border: 1px solid #EBEBEB;
      background-color: #F6FAFB;
      background-size: 113px 159px;
      background-repeat: no-repeat;
      background-position: right bottom;
      &.gift-cards-item__price--gift-gray {
        background-image: url("../img/gift-gray.png");
      }

      &.gift-cards-item__price--gift-green-2 {
        background-image: url("../img/gift-green-2.png");
      }

      &.gift-cards-item__price--gift-green {
        background-image: url("../img/gift-green.png");
      }

      &.gift-cards-item__price--gift-red {
        background-image: url("../img/gift-red.png");
      }

      h1 {
        font-size: $font-size-h1;
        text-align: left;
        color: #143C96;
      }

      label {
        font-size: 13px;
        font-weight: 500;
        position: absolute;
        left: 15px;
        bottom: 5px;
      }
    }

    p.purchase {
      margin: 15px 0;
      font-size: 11px;
      font-weight: 300;
      text-align: center;
      line-height: 1.2;
    }

    .gift-cards-item__add-to-cart {
      font-size: 14px;
      font-weight: 500;
      a {
        color: #143C96;
      }
    }
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .container {
    .gift-cards-item {
      .gift-cards-item__price {
        background-size: 93px 139px;
      }

    }
  }
}