@media only screen and (min-width: 901px) and (max-width: 1024px) {
  .bottom__section {
    .nav__item {
      padding-left: 4px !important;
      padding-right: 9px !important;

      a {
        font-size: 13px !important;
      }
    }
  }
}