.invoice{
  .header{
    text-align: right;
    h6{
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #4E5A61;
      line-height: 24px;
      margin: 0px;
    }
    span.logo{
        display: block;
        float: left;
        width: 134px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }
  }
  .modal-body{
    a.print-button {
      margin-top: 15px;
    }

    h6{
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 16px;
      color: #A9B811;
      line-height: 21px;
      margin: 20px 0px;
    }
    h6.centered{
      text-align: center;
      border-top: 1px solid #EBEBEB;
      padding-top: 25px;
    }
    .details{
      display: inline-block;
      p{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #4E5A61;
        span.value{
          display: block;
          font-weight: 700;
          font-size: 16px;
        }
      }
    }
    .details.right{
      float: right;
    }
    .field{
      padding: 15px 20px;
      margin-bottom: 20px;
      background-color: #F6FAFB;
      border-radius: 2px;
      border: 1px solid #EBEBEB;
      h6{
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 16px;
        color: #4E5A61;
        margin: 0px;
        line-height: 21px;
        span{
          display: inline-block;
          line-height: 21px;
          float: right;
          font-weight: 400;

        }
      }
    }
    .address{
      h6{
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 16px;
        color: #4E5A61;
        line-height: 21px;
        margin: 0px;
        margin-bottom: 20px;
      }
      p{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #4E5A61;
        line-height: 26px;
        span.value{
          display: block;
        }
      }
    }
    .wrapper{
      border-bottom: 1px solid #EBEBEB;
      padding-bottom: 15px;
      h6{
        display: inline-block;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 13px;
        color: #4E5A61;
        margin: 0px;
      }
      h6.right{
        float: right;
      }
    }
    .item{
      position: relative;
      padding: 15px 0px;
      border-bottom: 1px solid #EBEBEB;
      &.readOnly {
        opacity: 0.5;
      }
      p{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #4E5A61;
        margin: 0px;
        line-height: 30px;
        span.cond{
          font-weight: 700;
        }
        span.qty{
          font-weight: 300;
          margin-left: 90px;
        }
      }
      .photo{
        display: inline-block;
        vertical-align: middle;
        padding-right: 10px;
        width: 70px;

        img {
          max-width: 100%;
        }
      }
      .content{
        display: inline-block;
        vertical-align: middle;
      }
      .price{
        position: absolute;
        bottom: 16px;
        right: 0;
      }
      span.right{
        float: right;
      }
    }
    .order-values{
      border-bottom: 1px solid #EBEBEB;
      .item{
        margin-bottom: 0px;
      }
    }
  }
}