@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .featured-category {
    .featured-category__icon-container {
      width: $featured_category_icon_container_dimension_sm_down;
      height: $featured_category_icon_container_dimension_sm_down;
      margin: 15px auto;
      line-height: $featured_category_icon_container_dimension_sm_down;

      .featured-category__icon {
        width: $featured_category_icon_size_sm_down;
        height: $featured_category_icon_size_sm_down;
      }
    }
  }
}