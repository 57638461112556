.app-footer {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  background-color: $footer_top_section_background_color;
}

.footer-trust-section {
  .container {
    margin-bottom: 2.5rem;
    border-bottom: 1px solid #DFE5ED;
  }
}

.footer-links-section {
  color: $footer_top_section_text_color;
  padding-bottom: 40px;

  .footer__links-list {
    .links-category__title {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 20px;
      line-height: 21px;
    }

    .links-category__container {
      padding: 0;
      margin-bottom: 30px;

      li {
        line-height: 26px;

        a {
          color: #4E5A61;
          font-weight: 300;
          font-size: 14px;
        }
      }
    }
  }
}

.footer-social-links {
  margin-top: 20px;
}

.footer-social-link {

}

.footer-social-link__icon {
  font-size: 26px;
  margin-right: 10px;

  &.icon-facebook {
    color: #38599A;
  }

  &.icon-twitter {
    color: #12A2F3;
  }
}

.footer-copyright {
  background-image: linear-gradient(-46deg, $footer_bottom_section_background_color_1 0%, $footer_bottom_section_background-color_2 100%);
  padding-top: 20px;
  padding-bottom: 20px;

  p {
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    color: $footer_bottom_section_text_color;
    opacity: 0.9;
    line-height: 21px;
    margin: 0;
  }
}