.sidebar-availability {
  margin-bottom: 15px;
  border: 1px solid #EBEBEB;
  background-color: #F6FAFB;
  padding: 20px;
  border-radius: 2px;

  h4 {
    font-weight: bold;
    margin-bottom: 15px;
    color: #617178;
  }

  div {
    padding: 7px 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  p {
    margin-bottom: 10px;

    strong {
      font-weight: bold;
    }

    a {
      font-weight: bold;
      text-decoration: underline;
      color: #143C96;
    }
  }

  ul {
    li {
      margin-bottom: 10px;
      position: relative;
      font-weight: bold;

      button {
        border: none;
        padding: 0;
        width: 14px;
        height: 14px;
        border-radius: 14px;
        background-color: #C4CCD0;
        color: white;
        line-height: 14px;
        text-align: center;
        font-size: 12px;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -7px;
        text-indent: 1px;

        &:hover,
        &:focus,
        &:active {
          outline: none;
        }
      }
    }
  }

  .stores-title {
    display: flex;
    justify-content: space-between;
  }

  .clear-stores-btn,
  .choose-your-stores-btn {
    padding: 0;
    border: none;
    color: #143C96;
    text-decoration: underline;
    font-weight: bold;
  }

  .clear-stores-btn {

  }
}