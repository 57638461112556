.about-us {

  span.underline {
    text-decoration: underline;
    font-weight: normal;
  }

  div.about-us__ways-apply {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 30px;
    line-height: 24px;
    span.about-us__ways-apply__title {
      font-weight: 900;
    }
    ul.about-us__ways-apply__list {
      color: red;
    }
  }

  ul.about-us__article-list {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #4E5A61;
    line-height: 24px;
    list-style: disc;
    margin: 5px 0 5px 25px;
  }
}