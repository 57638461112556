@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

//.box { @include border-radius(10px); }

@mixin font-setup($size, $weight, $color) {
  font-family: $font-stack;
  font-weight: $weight;
  font-size: $size;
  color: $color;
  -webkit-font-smoothing: antialiased;
}

@mixin btn-custom($font-color, $background-color, $shadow-color) {
  display: inline-block;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 700;
  font-size: 14px;
  color: $font-color;
  border: none;
  background: $background-color;
  box-shadow: 0 1px 0 0 $shadow-color;
  border-radius: 100px;
  padding: 0 20px;
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 100%;
  max-width: 220px;
  margin: 0 10px;
  margin-bottom: 10px;
  span {
    display: inline-block;
    vertical-align: middle;
  }
  span.icon {
    margin-right: 5px;
  }
}

.product--list-view {
  display: table;
  border-bottom: 1px solid #EBEBEB;
  padding-bottom: 20px;
  margin-bottom: 20px;
  width: 100%;

  .product-image {
    display: block;
    float: left;
    width: $product-photo-width;
    height: auto;
    text-align: center;
    position: relative;
  }

  .product-actions-buttons {
    width: 200px;
  }

  .product-image__wishlist-btn {
    position: absolute;
    right: 10px;
    top: 44px;
    z-index: 2;
  }

  .product-content {
    display: block;
    margin-left: $product-photo-width;
    vertical-align: top;
    padding-left: 15px;
    padding-top: 34px;

    hr {
      width: 25px;
      margin: 5px 0 15px 0;
      //margin: 15px 0;
      border-top: 1px solid #EBEBEB;
    }

    form {
      padding-top: 30px;

      .form-group {
        //display: block;
        input[type=number] {
          @include font-setup(18px, 700, $primary-color);
          @include border-radius(100px);
          height: 35px;
          width: 119px;
          box-shadow: none;
          border: 2px solid #E8E8E8;
          text-align: center;
          margin: 0 10px;
          margin-bottom: 10px;
        }

        label {
          @include font-setup(14px, 700, $primary-color);
          margin: 0 10px;
        }

        .form-group__quantity {
          display: inline-block;
        }
      }
    }
  }

  .product-content__name {
    font-size: 16px;
    font-weight: bold;
  }

  .product-content__artist {
    font-weight: 700;
    font-size: 14px;
    color: #888888;
    margin: 0;
  }

  .product-content__release-date {
    color: $red;
    font-weight: bold;
  }

  .product-content__description {
    font-size: 14px;
    line-height: 24px;
    color: #4E5A61;

    .read-more-button {
      display: inline-block;
      margin-left: 5px;
      color: #143C96;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .product--list-view {
    .photo {
      width: 106px;
    }

    .content {
      margin-left: 106px;
    }
  }
}

/*
  ##Device = Tablets, Ipads (specific)
  ##Screen = B/w 1025 to 1200
*/
@media (min-width: 1025px) and (max-width: 1200px) {
  .product--list-view {
    form {
      .form-group__quantity {
        width: 100%;
        margin-bottom: 5px;
      }
    }
  }
}
