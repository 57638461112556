.header-search-form {
  display: inline-block;

  .vintage-autocomplete {
    position: relative;

    .vintage-autocomplete__select-wrapper {
      display: inline-block;
      position: relative;
      width: 90px;
      height: 35px;
      background-color: #f6fafb;
      overflow: hidden;
      border-radius: 2px 0 0 2px;

      &, > * {
        cursor: pointer;
      }

      select {
        background: transparent;
        border: 0;
        outline: 0;
        padding: 10px 25px 6px 8px;
        color: #4E5A61;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 900;

        -webkit-user-select: none;
        -moz-user-select: -moz-none;
        -ms-user-select: none;
        user-select: none;

        -webkit-appearance: none;
        -moz-appearance: radio-container;
        text-align-last: center;
        padding-bottom: 24px;
      }

      &.vintage-autocomplete__select-wrapper-noimage {
        &:after {
          content: '';
          width: 0;
          position: absolute;
          right: 6px;
          top: 50%;
          margin-top: -3px;
          border-width: 6px 4px;
          border-style: solid;
          pointer-events: none;
          border-color: #656565 transparent transparent transparent;
        }
      }
    }

    .header-search-input {
      font-family: 'Roboto', sans-serif, FontAwesome !important;
      background: #FFFFFF;
      border: 2px solid #EAEAEA;
      border-radius: 2px;
      font-weight: 400;
      font-size: 14px;
      color: #677889;
      height: 35px;
      line-height: 35px;
      padding-top: 0;
      padding-bottom: 0;
      width: 440px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      &::placeholder {
        color: #677889;
      }
    }

    .fa-padding {
      padding-top: 10px;
    }

    .has-feedback {
      a:last-child {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        z-index: 1000;
      }
      button.icon {
        position: absolute;
        top: 50%;
        left: 20px;
        margin-top: -8px;
        color: #4E5A61;
        font-size: 16px;
        border: none;
        box-shadow: none;
        padding: 0;
      }
      input {
        padding-left: 45px;
        border-radius: 0 2px 2px 0 !important;
        border-width: 1px !important;
      }
    }
  }

  .vintage-autocomplete__box {
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 10;
    max-height: 50vh;
    overflow-y: auto;
    background-color: white;
    display: none;
    box-shadow: 0 2px 2px 0 #AAAAAA;

    &.vintage-autocomplete__box--open {
      display: block;
    }

    p {
      display: block;
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      margin: 0;
    }
  }

  .vintage-autocomplete__suggestions {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .suggestion {
    // In order to the javascript scroll code to work is important that all
    // the suggestions in the list are of the same height.
    display: block;
    padding: 7px 20px;
    cursor: pointer;
    color: #708090;

    &.suggestion--header {
      > span {
        text-transform: uppercase;
        color: darken(white, 30%);
        font-size: small;
      }
    }

    &.suggestion--focused {
      background-color: #5367FA;
      color: white;

      span.separator {
        color: white;
        display: block;
        width: 100%;
        border-bottom: 1px dotted white;
      }
    }

    * {
      pointer-events: none;
    }
  }

  .link-advanced-search {
    text-align: center;
    font-weight: bold;
    padding: 15px 0;
    border-top: 1px solid #EBEBEB;
  }
}