.wishlist-share-modal-form {
  .wishlist-share-modal-form-social {
    background-color: #f6fafb;
    padding: 30px 30px;
    margin: 20px 0;
    label {
      vertical-align: middle;
      margin: 5px 0 !important;
    }
    .wishlist-share-modal-form-social__buttons {
      display: inline-block;
      float: right;
      .SocialMediaShareButton {
        display: inline-block;
        margin-right: 8px;
      }
      .SocialMediaShareButton:last-child {
        margin-right: 0 !important;
      }
      span[class^="icon-twitter"] {
        color: #12A2F3;
      }
      a {
        font-size: 25px;
        margin-right: 8px;
      }

      a:last-child{
        margin-right: 0;
      }
    }
  }
  .wishlist-share-modal-form-buttons {
    text-align: right;
  }
}