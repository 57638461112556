.product-icon-wrapper {
  height: 35px;
  width: 140px;
  margin: auto;
  border: 1px solid #eee;
  border-bottom: none;
  padding-top: 5px;

  -webkit-border-top-left-radius: 80px;
  -webkit-border-top-right-radius: 80px;
  -moz-border-radius-topleft: 80px;
  -moz-border-radius-topright: 80px;
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
}

.product-icon {
  height: 20px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  &.icon-default {
    background-image: url("../img/icon-default.png");
  }

  &.icon-xbox-360 {
    background-image: url("../img/icon-xbox-360.jpg");
  }

  &.icon-xbox-kinect {
    background-image: url("../img/icon-xbox-kinect.jpg");
  }

  &.icon-xbox {
    background-image: url("../img/icon-xbox.jpg");
  }

  &.icon-xbox-one {
    background-image: url("../img/icon-xbox-one.jpg");
  }

  &.icon-psvita {
    background-image: url("../img/icon-psvita.jpg");
  }

  &.icon-psp {
    background-image: url("../img/icon-psp.jpg");
  }

  &.icon-playstation {
    background-image: url("../img/icon-playstation.jpg");
  }

  &.icon-playstation-2 {
    background-image: url("../img/icon-playstation-2.jpg");
  }

  &.icon-playstation-3 {
    background-image: url("../img/icon-playstation-3.jpg");
  }

  &.icon-playstation-4 {
    background-image: url("../img/icon-playstation-4.jpg");
  }

  &.icon-pc {
    background-image: url("../img/icon-pc.jpg");
  }

  &.icon-wii {
    background-image: url("../img/icon-wii.jpg");
  }

  &.icon-wii-u {
    background-image: url("../img/icon-wii-u.jpg");
  }

  &.icon-gameboy-advance {
    background-image: url("../img/icon-gameboy-advance.jpg");
  }

  &.icon-nintendo-ds {
    background-image: url("../img/icon-nintendo-ds.jpg");
  }

  &.icon-nintendo-3ds {
    background-image: url("../img/icon-nintendo-3ds.jpg");
  }

  &.icon-nintendo-switch {
    background-image: url("../img/icon-nintendo-switch.jpg");
  }

  &.icon-game {
    background-image: url("../img/icon-game.jpg");
  }

  &.icon-toy {
    background-image: url("../img/icon-toy@2x.png");
  }

  &.icon-poster {
    background-image: url("../img/icon-poster@2x.png");
  }

  &.icon-dvd {
    background-image: url("../img/icon-dvd.jpg");
  }

  &.icon-bluray {
    background-image: url("../img/icon-bluray.jpg");
  }

  &.icon-4k-blu-ray {
    background-image: url("../img/4k-blu-ray.png");
  }

  &.icon-cd {
    background-image: url("../img/icon-cd.jpg");
  }

  &.icon-cassette {
    background-image: url("../img/icon-cassette.jpg");
  }

  &.icon-vinyl {
    background-image: url("../img/icon-vinyl.jpg");
  }
}

.product-icon--without-wrapper {
  min-width: 100px;
  display: inline-block;
}