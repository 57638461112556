@media (min-width: 768px) and (max-width: 992px) {
  .sub-header__menu {
    .menu__item {
      margin-right: 20px !important;

      a, button {
        font-size: 13px !important;
      }
    }

    .dropdown {
      span {
        font-size: 13px !important;
      }
    }

    .menu_vline {
      margin-left: 5px !important;
      margin-right: 5px !important;
    }
  }
}