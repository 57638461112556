.home__slider {
  overflow: hidden;

  .slide__img {
    width: 100%;
  }

  .slick-dots {
    bottom: 0;

    li {
      button {
        &:before {
          content: "";
          background-color: transparent;
          border: 1px solid white;
          border-radius: 100%;
          width: 10px;
          height: 10px;
          opacity: 1;
        }
      }

      &.slick-active {
        button {
          &:before {
            background-color: white;
          }
        }
      }
    }
  }
}