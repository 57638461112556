.alert {
  margin: 24px 0;
  padding: 12px 24px;
  font-weight: bold;
  border-radius: 2px;
  text-transform: uppercase;

  &.alert-danger {
    background-color: rgba(239, 83, 80, .5);
    border: 1px solid rgba(198, 40, 40, .5);
    color: #A92323;
  }
}

