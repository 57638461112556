/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  body {
    br.sm-only {
      display: block;
    }
    header {
      .navbar.top .navbar-collapse .nav li {
        padding-left: 5px;
        padding-right: 5px;
      }
      .header-middle {
        .top {
          form.header-search-form .form-group input {
            width: 150px;
          }
          ul.menu li {
            margin-right: 20px;
            a, .dropdown button {
              font-size: 12px;
            }
          }
          ul.menu.right .dropdown button span {
            font-size: 12px;
          }
        }
      }
    }
    section#content {
      .event-box span.image {
        height: 190px;
        width: 190px;
        background-position: -120px center;
      }
    }
    section#ad {
      .box span.bg {
        left: 0;
        width: 380px;
      }
    }

    section#categories {
      .grid {
        .item {
          div.icon {
            width: 120px;
            height: 120px;
            margin: 10px auto;
            line-height: 120px;
            span {
              height: 70px;
              width: 70px;
            }
          }
        }
      }
    }
  }
  section.page-content {
    .wrapper.wishlist .wish-item .right {
      float: none;
    }
    .wrapper.locations .left .finder .results {
      padding-right: 15px;
    }
  }
  .login {
    .login-wrapper .lightbox .brand span.logo {
      margin: 36px auto 0px auto;
    }
  }
}

/*
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  body {
    header {
      .navbar.top .navbar-collapse .nav li {
        padding-left: 5px;
        padding-right: 5px;
      }

      .header-middle {
        .top {
          ul.menu {
            li {
              margin-right: 20px;
            }
          }

          form.header-search-form .form-group input {
            max-width: 200px !important;
          }

          .menu {
            &.right {
              .btn-group {
                .icon-user {
                  .name-user {
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                    width: auto;
                    text-align: center;
                    padding-left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  body {
    br.sm-only {
      display: block;
    }
    header {
      .header-middle {
        .top {
          form.header-search-form .form-group input {
            width: 280px;
          }
        }
        .bottom {
          ul {
            li {
              padding-left: 6px;
              padding-right: 9px;
              a {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
    section#content {
      .event-box span.image {
        height: 250px;
        width: 250px;
        background-position: -120px center;
      }
    }

    section#categories {
      .grid {
        .item {
          div.icon {
            width: 160px;
            height: 160px;
            margin: 15px auto;
            line-height: 160px;
            span {
              height: 80px;
              width: 80px;
            }
          }
        }
      }
    }
  }
}

// iPad pro specific
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  body {
    header {
      .header-middle {
        .top {
          form.header-search-form .form-group input {
            width: 440px;
          }
        }
        .bottom {
          ul {
            li {
              padding-left: 6px;
              padding-right: 9px;
            }
          }
        }
      }
    }
  }
}

/*
  ##Device = Ipad specific
  ##Screen = B/w 1024px to 1200px (portrait)
*/
@media only screen and (min-width: 1025px) and (max-width: 1200px)  and (orientation: portrait) {
  body {
    section#categories {
      .grid {
        .item {
          div.icon {
            width: 160px;
            height: 160px;
            margin: 10px auto;
            line-height: 160px;
            span {
              height: 80px;
              width: 80px;
            }
          }
        }
      }
    }

    section.product-details {
      form {
        .form-group__quantity {
          width: 100%;
          margin-bottom: 5px;
        }
      }
    }
  }
}

/*
  ##Device = Ipad specific
  ##Screen = B/w 768px to 900px
*/
@media only screen and (min-width: 768px) and (max-width: 900px) {
  body {
    header {
      .header-middle {
        .bottom {
          ul {
            padding-top: 5px;
            padding-bottom: 5px;
            li {
              padding-left: 6px;
              padding-right: 10px;
              a {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}

/*
  ##Device = Ipad specific
  ##Screen = B/w 768px to 900px
*/
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  body {
    header {
      .header-middle {
        .bottom {
          ul {
            li {
              padding-left: 6px;
              padding-right: 12px;
              a {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}

/*
  ##Device = Ipad specific
  ##Screen = B/w 1024px to 1200px
*/
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  body {
    header {
      .header-middle {
        form.header-search-form .form-group input {
          max-width: 280px !important;
        }

        .menu {
          &.right {
            .btn-group {
              .icon-user {
                padding-left: 0;
                .name-user {
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  vertical-align: middle;
                  width: auto;
                  text-align: right;
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}