.choose-your-stores-modal-body {
  .selected-stores {
    ul {
      li {
        display: inline-block;
        background-color: #EBEBEB;
        color: #63737B;
        font-weight: bold;
        padding: 10px;
        margin-right: 10px;
        margin-bottom: 10px;

        button {
          border: none;
          padding: 0;
          text-align: center;
          width: 14px;
          height: 14px;
          border-radius: 14px;
          line-height: 14px;
          background-color: #C5CBCE;
          color: #EBEBEB;
          margin-left: 10px;
        }
      }
    }
  }

  .selected-stores {
    margin-bottom: 30px;
  }

  .locations-list {
    > div {
      width: 100% !important;
    }
  }

  .location-card {
    margin-bottom: 30px;

    label {
      display: flex;
      align-items: center;
    }

    input {
      margin-right: 10px;
    }

    p {
      margin-bottom: 5px !important;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    strong {
      font-weight: bold;
    }
    .ship-limit-message{
      font-weight: bold;
      text-decoration: underline;
    }
  }
}