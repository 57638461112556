.quantity-input {
  position: relative;
  display: inline-block;

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type=number] {
    border-radius: 100px !important;
    height: $quantity-input-height-lg !important;  // same as add to cart button
    width: 119px;
    padding: 0 !important;
    box-shadow: none;
    border: 2px solid #E8E8E8;
    text-align: center;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    color: #4E5A61 !important;
    margin: 0 !important;
  }

  label {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 700;
    font-size: 14px;
    color: #4E5A61;
    margin-right: 10px;
  }
}

.quantity-input__control {
  position: absolute;
  top: 0;
  display: inline-block;
  height: 100%;
  width: $quantity-input-height-lg;
  text-align: center;
  line-height: $quantity-input-height-lg;
  font-size: round($quantity-input-height-lg * 0.5);
  font-weight: 700;
  color: #4E5A61;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.quantity-input__control--plus {
  right: 0;
}

.quantity-input__control--minus {
  left: 0;
}

.quantity-input--full-width {
  width: 100%;

  input[type=number] {
    width: 100%;
  }
}

.quantity-input--size-md {
  input[type=number] {
    height: $quantity-input-height-md !important;
  }

  .quantity-input__control {
    line-height: $quantity-input-height-md;
    font-size: round($quantity-input-height-md * 0.65);
  }
}
