.product--grid-view {

}

.product-card {
  height: 100%;
  border: 1px solid #eee;
  border-top: none;
  position: relative;
  padding-bottom: 238px;  // height of the product-actions
}

.product-card__wishlist-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
}

.product-card__icon {
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 100%;
}

.product-card-info {
  padding: 0 10px;
  text-align: center;
}

.product-card-info__name {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #4E5A61;
  margin: 13px 0;
  line-height: 21px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-card-info__release-date {
  color: #AE1A20;
  font-weight: bold;
}

.product-card-info__artist {
  font-weight: 700;
  font-size: 14px;
  color: #888888;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-card-actions {
  position: absolute;
  bottom: 0;
  padding: 0 10px 10px 10px;
  width: 100%;
}

.product-card-actions__price-selector {

}

.product-card-actions__quantity-input {
  margin-bottom: .5rem;
}

.product-card-actions__actions {
  display: flex;
  justify-content: space-evenly;

  // Modifying these buttons here is a bad practice because these are external components
  // TODO modify them by props
  .btn-custom {
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    height: 35px;
    line-height: 35px;
    border-radius: 35px !important;

    span.icon {
      margin: 0;
      font-size: 16px;
    }

    &:first-child {
      margin-right: .25rem !important;
    }

    &:last-child {
      margin-left: .25rem !important;
    }
  }
}
