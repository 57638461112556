// Background variables
$newsletter_subscription_background_img_url: '../img/news-bg.jpg';
$newsletter_subscription_padding_container: 50px;

// Colors
$newsletter_subscription_primary_color: #143C96;
$newsletter_subscription_subtitle_color: #4E5A61;

$newsletter_subscription_button_box_shadow_color: #072059;
$newsletter_subscription_button_text_color: #FFF;
$newsletter_subscription_button_text_shadow_color: rgba(0, 0, 0, 0.50);

// Font sizes & text
$newsletter_subscription_title_font_size: 28px;
$newsletter_subscription_subttitle_font_size: 16px;

$newsletter_subscription_text_margin_bottom: 20px;



