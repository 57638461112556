.notify-me-when-available {
  display: block;
  text-align: center;

  .notify-me-when-available__button {
    background: none;
    border: 1px solid #eee;
    box-shadow: none;

    span.icon {
      margin-right: 5px;
    }
  }
}