.header-bottom-section {
  hr {
    width: 100%;
    border-top-width: initial;
    border-right-width: initial;
    border-left-width: initial;
    border-top-color: initial;
    border-right-color: initial;
    border-left-color: initial;
    background: none transparent;
    border-style: none none solid;
    border-image: initial;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin: 0;
  }
}

.header-bottom-nav {
  text-align: center;
  padding: 15px 0;
  margin: 0;
  display: flex;
  justify-content: space-between;

}

.header-bottom-nav__item {
  position: relative;
  line-height: 30px;
  padding-left: 16px;
  padding-right: 16px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  a {
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: rgb(255, 255, 255);
  }
}

.header-bottom-nav__item--separator {
  padding: 0;

  span {
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -2px;
    height: 4px;
    width: 4px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
  }
}
