.modal.subscribed-modal {
  .modal-header, .modal-footer {
    text-align: center !important;
  }

  .modal-header {
    .icon-circle-check, .icon-mail {
      color: #173F94;
      font-size: 35px;
    }

    .close {
      top: 0
    }
  }

  .modal-body {
    text-align: center;
    padding-top: 0;

    p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 30px;

      a {
        color: #DCE659;
      }
    }
  }

  .modal-footer {
    button {
      width: 100%;
      max-width: 200px;
    }
  }
}
