.trust-item {
  margin: 0;
  font-size: 16px;
  color: #4E5A61;
  display: flex;
  justify-content: center;

  span {
    font-weight: normal;
  }

  a {
    font-weight: bold;
    text-decoration: none;
    color: #4E5A61;
    white-space: nowrap;
  }
}

.trust-item--align-center {
  text-align: center !important;

  .trust-item__text {
    flex: 0 1 60%;
    color: #4E5A61;
  }
}

.trust-item--align-left {
  justify-content: flex-start;
  margin-bottom: 1.25rem;
}

.trust-item__icon {
  align-self: center;
  padding-right: 5px;

  i[class*="icon"] {
    color: #143C96;
    font-size: 18px;
    position: relative;
    top: 2px;
    margin-right: 3px;
  }
}