/* Welcome to Compass. Use this file to write IE specific override styles.
 * Import this file using the following HTML or equivalent:
 * <!--[if IE]>
 *   <link href="/stylesheets/ie.css" media="screen, projection" rel="stylesheet" type="text/css" />
 * <![endif]--> */

body.ie-not-supported {
  min-height: 100%;
  height: 100%;
  background-color: #F6FAFB;

  div#root {
    min-height: 100%;
    height: 100%;
  }

  div#ie-not-supported {
    text-align: center;
    padding-top: 5rem;

    img {
      width: 380px;
      height: 432px;
    }
  }
}
