div.checkout-success-alert {
  background-image: linear-gradient(-135deg, #DAEEF3 0%, #F6FAFB 100%);
  font-size: 17px;
  color: #143C96;
  border-radius: 2px;
  padding: 5px 0;
  margin-bottom: 40px;
  position: relative;
  height: 60px;
  font-weight: bold;
  overflow: hidden;

  &:before {
    content: "";
    height: 92px;
    width: 92px;
    position: absolute;
    background: #DCEFF3;
    top: 50%;
    left: 92px * -.5;
    opacity: .8;
    background-size: auto 80%;
    border-radius: 50px;
    margin-top: 92px * -.5;
  }

  a {
    color: #143C96;
    text-decoration: underline;
  }

  h4 {
    margin: 10px 12px 12px 50px;
    color: #143C96;
  }

  span i {
    color: #ACCFD7;
    font-size: 30px;
    position: relative;
    top: 0;
    left: -20px;
    vertical-align: middle;
  }
}