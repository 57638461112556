.wrapper {
  .text-section {
    border-left: 1px solid #EBEBEB;

    .contact-channel {
      margin-top: 15px;

      .contact-channel__channel-icon{
        background-color: #1B8816;
        width: 32px;
        height: 32px;
        border-radius: 50%;

        span {
          color: #173F94;
        }
      }
    }
  }
  p {
    text-align: justify;

    a {
      color: #143C96 !important;

      &.here-link:hover {
        border-bottom: 2px solid #143C96;
      }
    }
  }

  .contact-info {
    .contact-item {

    }
  }
}