.price-label {
  text-transform: uppercase;

  span {
    display: block !important;
    font-weight: bold;

    &:first-child {
      font-size: smaller;
      margin-bottom: 3px;
    }

    &:last-child {
      font-size: large;
    }

    &.green {
      color: #A9B811;
    }

    &.blue {
      color: #143C96;
    }
  }
}

.price-label--left {
  text-align: left;
}

.price-label--center {
  text-align: center;
}