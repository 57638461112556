#price-form {
  div[class*="col"] {
    padding-right: 0;
  }

  button {
    height: 50px;
    padding: 0 0 0 15px;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    color: #143C96;
    font-weight: bold;
    text-decoration: underline;

    &:active,
    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}