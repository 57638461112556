.auth-social-buttons {
  .social-button {
    color: white;
  }

  .social-button--facebook {
    background-color: #3B5998;
  }

  .social-button--google {
    background-color: #D34836;
  }
}