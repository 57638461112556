@media (max-width: 768px) {
  .sub-header__menu {
    .menu__item {
      margin-right: 7px !important;
      font-size: 10px !important;
    }

    .dropdown {
      span, button {
        font-size: 10px !important;
      }
    }

    .menu_vline {
      margin-left: 5px !important;
      margin-right: 5px !important;
    }
  }
}