.checkout-step.checkout-step--payment-method {

  .checkout-step__body {

    .radio label{
      width: 100%;
    }

    .paypal-info {
      float: right;

      .paypal-logo {
        background-image: url('../img/paypal-logo.png');
        width: 80px !important;
      }
    }

    .credit-card--info {
      float: right;

      .icon {
        width: 40px !important;

        &.credit-card-logo__master-card {
          background-image: url('../img/credit-card-mastercard.png');
        }
        &.credit-card-logo__amex {
          background-image: url('../img/credit-card-amex.png');
        }
        &.credit-card-logo__visa {
          background-image: url('../img/credit-card-visa.png');
        }
        &.credit-card-logo__discover {
          background-image: url('../img/credit-card-discover.png');
        }
      }
    }
  }
}
