/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  li.dropdown-submenu {
    & span:focus > .dropdown-menu {
      display: block;
    }
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  li.dropdown-submenu {
    & span:focus > .dropdown-menu {
      display: block;
    }
  }
}

.sub-header__menu{
  .dropdown {
    .dropdown-menu {
      border-radius: 2px;
      padding: 0;

      li {
        margin: 0;
        display: block;
        line-height: 36px;

        &.dropdown-submenu {
          span {
            display: block;
            text-align: left !important;
            font-size: 14px;
            padding: 10px 15px;
            line-height: 20px;
            font-weight: 700;

            a {
              font-family: 'Roboto', sans-serif;
              color: #4E5A61;
            }

            &:after {
              float: right;
              display: block;
              font-family: icomoon;
              content: "";
              width: 20px;
              height: 20px;
              font-size: 8px;
              color: rgb(147, 155, 160);
              line-height: 20px;
              text-align: right;
            }

            &:hover {
              background-color: #F6FAFB
            }
          }

          &.gift-cards {
            span {
              &:after {
                content: '' !important;
              }
            }

            a {
              text-align: left;
              font-weight: 700;
              padding: 0;
            }
          }

          & span:hover {
            background-color: #F6FAFB;
          }
          & span:hover > .dropdown-menu {
            display: block;
          }

          .dropdown-menu {
            box-shadow: none;
            position: absolute;
            top: 0;
            left: 100%;
            background-color: rgb(255, 255, 255);
            margin-top: 0;
            border-radius: 2px;
            border-width: 1px;
            border-style: solid;
            border-color: rgb(216, 216, 216);
            border-image: initial;

            .sub-categories {
              min-width: 400px;
              min-height: 212px;
              cursor: auto;
              padding: 20px;

              h6 {
                text-align: left;
                font-family: Roboto, sans-serif;
                font-weight: 700;
                font-size: 13px;
                color: rgb(169, 184, 17);
                line-height: 18px;
                -webkit-font-smoothing: antialiased;
                margin: 0 0 10px;
              }

              ul {
                display: inline-block;
                margin: 0;
                padding: 0 20px 0 0;

                li {
                  line-height: 19px;
                  margin-bottom: 5px;

                  a {
                    font-family: Roboto, sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    color: rgb(97, 113, 121);
                    line-height: 19px;
                    background: none transparent;
                    padding: 0 !important;
                  }

                  &:first-child {
                    a {
                      border-top-left-radius: 2px;
                      border-top-right-radius: 2px;
                    }
                  }
                }
              }
            }

            &.toy {
              .sub-categories {
                background-position: right center;
                background-size: contain;
                background-repeat: no-repeat;
              }
            }
            &.movie {
              .sub-categories {
                background-position: right bottom;
                background-size: 283px;
                background-repeat: no-repeat;
              }
            }
            &.poster {
              .sub-categories {
                width: 540px;
                height: auto;
                background-position: right bottom;
                background-size: 283px;
                background-repeat: no-repeat;
              }
            }
            &.gaming {
              .sub-categories {
                background-position: right bottom;
                background-size: 283px;
                background-repeat: no-repeat;
              }
            }
            &.accessory {
              .sub-categories {
                background-position: right bottom;
                background-size: 230px;
                background-repeat: no-repeat;
              }
            }
            &.music {
              .sub-categories {
                background-position: right bottom;
                background-size: 230px;
                background-repeat: no-repeat;
              }
            }
          }
        }

        a {
          display: block;
          text-align: left;
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 14px;
          color: #4E5A61;
          line-height: 19px;
          padding: 20px 20px;
          -webkit-font-smoothing: liased;

          span {
            display: inline-block;
            vertical-align: middle;

            &.icon {
              margin-right: 10px;
              margin-left: 0;
              color: #D2DDE4;
              font-size: 20px;
            }
          }

          &:hover {
            background-color: #F6FAFB;
          }
        }

        &:first-child {
          a {
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
          }
        }

        &:last-child {
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }
    }
  }
}
