.about-faq {
  .about-faq__wrapper-panel-content {
    margin-bottom: 15px;

    .panel-body {

      a {
        cursor: pointer;
        color: #B5C420 !important;
      }
    }
  }
}

.faq-group {
  margin-bottom: 40px;
}

.faq-group__title {
  font-weight: bold !important;
  color: #4E5A61 !important;
  margin-bottom: 10px !important;
}
