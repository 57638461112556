.featured-categories {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;

  .featured-categories__title {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #143C96;
    margin-bottom: 20px;
    line-height: 26px;
    text-align: center;
  }
}