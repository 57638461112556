.header-account-dropdown-button__text {
  display: inline-block;
  width: auto;
  padding-left: 0 !important;
}

.header-account-dropdown-button__text {
  color: #D2DDE4;
}

.header-account-dropdown-item {
  .header-account-dropdown-item__link {
    .link-display-text {
      color: #4E5A61;
    }

    .icon {
      color: rgba(255, 255, 255, 0.4);
      margin-right: 10px;
      margin-left: 0;
    }
  }
}