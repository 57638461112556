.product-available-in {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  div:first-child {
    margin-right: 8px;
  }

  strong {
    font-weight: bold;
  }
}