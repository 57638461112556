.cassette {
  display: block;
  width: 72px;
  height: 48px;
  margin: 2% auto;
  background: url('../img/cassette-3.svg') left center;
  background-size: 1800px 48px;
  animation: play .7s steps(5) infinite;
}

@keyframes play {
  100% {
    background-position: -1800px;
  }
}