#credit-card-form {
  label.by_default {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #4E5A61;
    margin-bottom: 10px;
  }

  .billing-address {
    .select-address {
      width: 50%;
      display: inline-block;

    }
    .billing-address__change-link {
      display: inline-block;
      float: right;

      a {
        color: #A9B811;
      }
    }
  }

  .billing-info {
    position: relative;

    .credit-card-form__address-button {
      button{
        position: absolute;
        top: -5px;
        right: 0;
        height: 35px;
        padding: 0 30px;
        margin-left: 0;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: #143C96;
        border-radius: 100px;
        line-height: inherit;
        box-shadow: none;
        background-color: #DCE84C;
      }
    }

    .credit-card-form__address-list {
     margin-top: 40px;
    }
  }

  ul.credit-card-form__address-list {
    > li {
      border-top: 1px solid #EBEBEB;
      border-bottom: 1px solid #EBEBEB;
      label {
        font-weight: normal;
      }
    }
    > li:first-child {
      border-top: 2px solid #EBEBEB;
    }
    > li:last-child {
      border-bottom: 2px solid #EBEBEB;
    }
    &.radio {
      .form-field {
        display: inline-block;
        padding-left: 20px;
      }
    }
  }
}