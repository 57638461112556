.tracking-items {
  .modal-header {
    padding-bottom: 40px;

    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #4E5A61;
      margin: 0;
      margin-top: 40px;
      line-height: 24px;
    }
  }

  .modal-body {
    padding: 0;
    .details {
      display: inline-block;
      p {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #4E5A61;
        span.value {
          display: block;
          font-weight: 700;
          font-size: 16px;
        }
      }
    }
    .details.right {
      float: right;
    }
    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 16px;
      color: #4E5A61;
      margin: 0;
      line-height: 19px;
    }
    h6 {
      font-family: 'Roboto', sans-serif !important;
      font-weight: 700;
      font-size: 14px;
      color: #4E5A61;
      line-height: 19px;
      -webkit-font-smoothing: antialiased;
    }
    .status-line {
      margin: 0;
      .column {
        position: relative;
        height: 100px;
        span {
          display: block;
        }
        span.line {
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          height: 16px;
          margin-top: -8px;
          background-color: #143C96;

          &.line--middle-with {
            width: 50%;
          }
        }
        .point {
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          text-align: center;
          margin-top: -16px;
          span.circle {
            display: inline-block;
            height: 32px;
            width: 32px;
            border-radius: 32px;
            background-color: #143C96;
            span.icon {
              line-height: 32px;
              text-align: center;
              color: #fff;
            }
          }
          span.text {
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 14px;
            color: #4E5A61;
            line-height: 19px;
            margin-top: 10px;
            text-align: center;
          }
        }
      }
      .column:first-child {
        span.line {
          left: 25px;
        }
        .point {
          position: absolute;
          top: 50%;
          left: 0;
          right: auto;
        }
      }
      .column:last-child {
        span.line {
          right: 25px;
        }
        .point {
          position: absolute;
          top: 50%;
          right: 0;
          left: auto;
        }
      }
    }
    .status {
      margin-bottom: 0;
      span {
        display: inline-block;
        vertical-align: middle;
        line-height: 24px;
      }
      span.circle {
        height: 14px;
        width: 14px;
        border-radius: 14px;
        background-color: #EEE;
        margin-right: 5px;
      }
      span.text {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 14px;
      }
    }
    .status.delivered {
      span.circle {
        background-color: #1B8816;
      }
      span.text {
        color: #1B8816;
      }
    }
    .status.in-transit {
      span.circle {
        background-color: #F4C400;
      }
      span.text {
        color: #F4C400;
      }
    }
    .status.awaiting {
      span.circle {
        background-color: #FF7E00;
      }
      span.text {
        color: #FF7E00;
      }
    }
    .status.cancelled {
      span.circle {
        background-color: #AE1A20;
      }
      span.text {
        color: #AE1A20;
      }
    }
    .timeline {
      .report {
        position: relative;
        padding-bottom: 15px;
        padding-left: 25px;
        span.point {
          position: absolute;
          top: 8px;
          left: 0;
          background-color: #E7E7E7;
          height: 8px;
          width: 8px;
          border-radius: 8px;
          display: block;
        }
        span.line {
          display: block;
          position: absolute;
          left: 3px;
          top: 0;
          bottom: 0;
          background-color: #E7E7E7;
          width: 2px;
        }
        span.date {
          font-family: 'Roboto', sans-serif !important;
          font-weight: 700;
          font-size: 14px;
          color: #626783;
          line-height: 24px;
          -webkit-font-smoothing: antialiased;
        }
        p {
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 16px;
          color: #626783;
          line-height: 21px;
        }
      }
      .report.active {
        span.point {
          background-color: #1B8816;
          height: 12px;
          width: 12px;
          border-radius: 12px;
          left: -2px;
          top: 6px;
        }
      }
      .report:first-child {
        span.line {
          top: 10px;
        }
      }
      .report:last-child {
        span.hline {
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: #E7E7E7;
          width: 8px;
          height: 2px;
        }
      }
    }
  }
}