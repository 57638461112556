.coupon-form-container {
  h6 {
    margin: 0;
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #4E5A61;
  }

  form {
    .form-group {
      width: 100%;
      margin-bottom: .3rem;

      &.has-error {
        position: relative;

        .form-control {
          border-color: rgba(#AE1A20, 0.5);
        }

        i {
          position: absolute;
          right: 15px;
          top: 15px;
          color: #AE1A20;
          font-size: 18px;
        }
      }

      input[type="submit"] {
        margin-top: 15px;
        padding: 0 100px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .form-control {
      border-width: 1px;
    }
  }

  .help-block {
    color: #999999;
    font-weight: 400;
    font-style: italic;
  }
}

.coupon-form-container--large {
  padding: 2rem 1.5rem;
  border: 1px solid #ebebeb;
}

.coupon-form-container--compact {
  padding: 0;

  .coupon-form-container__title {
    display: none;
  }

  .form-group {
    float: left;

    &:first-child {
      width: 60%;
    }

    &:last-child {
      width: 40%;
      padding-left: 1rem;
    }
  }

  input[type="submit"] {
    margin-top: 0 !important;
    height: 50px !important; // same as input
    width: 100%;
  }
}

/*
  ##Screen = B/w 768px to 1024px
*/
@media (max-width: 992px) {
  .coupon-form-container {
    form {
      .form-group {
        width: 100%;
        text-align: center;

        input[type="submit"] {
          width: 100%;
          display: block;
          margin-top: 10px;
          float: none;
        }
      }
    }
  }
}

