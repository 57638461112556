.checkout-address-list {
  .radio {
    width: 100%;

    a {
      float: right;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: #A9B811;
    }
  }
}