.shopping-cart {
  min-height: 0;
  padding: 15px;
  margin-bottom: 30px;
  border: 1px solid #ebebeb;
  position: relative;

  .item:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .summary {
    padding: 0;
    padding-top: 30px;

    span.span--shipping {
      color: red;
    }

    h4 {
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 16px;
      color: #4E5A61;
      line-height: 37px;
      margin: 0;
      -webkit-font-smoothing: antialiased;
      margin-bottom: 20px;

      a {
        float: right;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #4E5A61;
      }
    }

    .btn-custom {
      margin: 20px 0 0 0;
    }
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .shopping-cart .summary p a span.btn-custom {
    width: 100%;
    font-size: 12px;
  }
}