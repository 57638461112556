.terms-of-use {
  h1 {
    margin-bottom: 0 !important;
  }
  strong {
    font-weight: 900;
    font-size: 16px;
  }
  span.terms-of-use__send-email {
    color: #DCE84C;
  }
}