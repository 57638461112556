.coupon-details {
  padding: 1.2rem 1.7rem;
  border: 1px solid #ebebeb;
}

.coupon-details--compact {
  border-radius: 50px;
}

.coupon-name {
  font-weight: normal;

  i {
    color: $green;
  }
}

.coupon-delete-button {
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  color: $grayish-blue;
}
