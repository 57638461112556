.locations-page {
  .panel {
    border: none;
    box-shadow: none;

    .panel-heading {
      background-color: #DEE4F2;
      border: none;
      border-radius: 1px;
      box-shadow: none;
      padding: 0;

      .panel-title {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #4E5A61;
        position: relative;

        a {
          padding: 10px;
          display: block;

          span.icon-arrow-collapse {
            position: absolute;
            top: 0px;
            right: 0px;
          }

          span.icon-arrow-collapse::before {
            content: "\e907";
          }
        }

        a.collapsed {
          span.icon-arrow-collapse::before {
            content: "\e901";
          }
        }
      }
    }

    .panel-collapse {
      border: none;
      box-shadow: none;

      .panel-body {
        border: none;
        box-shadow: none;

        p {
          margin-bottom: 10px;
        }
      }
    }
  }

  .store-search-form {
    padding: 15px;
    margin-bottom: 10px;

    p {
      margin-bottom: 10px;
    }

    button {
      border: none;
    }
  }
}