.wishlist-share-button {
  display: inline-block;
  .wishlist-share-button__button-share {
    span.fa {
      margin-right: 5px;
    }

    &.btn-custom {
      padding: 0 30px;
      line-height: 37px;
      height: 37px;
    }
  }
}