.cancel-items {
  .modal-header {
    padding-bottom: 40px;
  }

  .modal-body {
    padding: 0;

    form {
      .form-group {
        width: 100%;
        margin-bottom: 20px;

        label {
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          color: #4E5A61;
          text-align: left;
          padding-right: 20px;
          height: 50px;
          line-height: 50px;
        }

        .form-control {
          width: 100%;
        }
      }
    }

    .table {
      margin-bottom: 20px;

      thead {
        tr {
          th {
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 13px;
            color: #4E5A61;
            border-bottom: 1px solid #EBEBEB;
            padding: 15px 0;
            -webkit-font-smoothing: antialiased;
          }

          th.right {
            text-align: right;
          }
        }
      }

      tbody {
        &.readOnly {
          opacity: 0.5;
        }

        .marked {
          display: inline-block;
          background-color: #EBEBEB;
          padding: 8px;
          font-weight: 700;
        }

        tr.table-reason__row {
          td {
            padding: 0;
          }
        }

        tr.table-reason__item {
          td {
            border: none;
            vertical-align: middle;
            padding: 15px 0;

            .item {
              padding: 0;
              border: none;
              .price {
                bottom: 0;
              }
            }

            .custom-checkbox {
              margin: 0;

              input {
                margin: 0;
              }

              label {
                margin: 0;
              }
            }
          }

          td.center {
            text-align: center;
          }
        }
      }
    }

    .modal-body__request_successful {
      text-align: center;
      h1 {
        word-spacing: 1px;
        margin-bottom: 0;
      }
      .fa {
        font-size: 50px;
        color: blue;
        margin-bottom: 15px;
      }
      p {
        width: 70%;
        line-height: 20px;
        margin: 30px auto;
      }
    }
  }
}