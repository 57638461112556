.nav__main-header_container {
  padding-top: 20px;
  padding-bottom: 20px;

  .nav__item {
    padding-left: 20px;
    padding-right: 20px;

    a {
      text-transform: uppercase;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: 13px;
      color: #4E5A61;
      padding: 0;
      line-height: 20px;

      span {
        display: inline-block;
        vertical-align: middle;
        padding-top: 2px;
        border-bottom: 2px solid transparent;

        &.nav__text {
          padding-top: 2px;
          border-bottom: 2px solid transparent;
        }

        &.icon {
          color: #143C96;
          font-size: 14px;
          margin-right: 10px;

          .icon-telephone {
            font-size: 20px;
          }
        }

        &.nav__vline {
          margin-left: 40px;
          margin-right: 20px;
          border-right: 1px solid #D9DFEA;
          height: 12px;
          width: 1px;
        }
      }

      &.active {
        color: #143C96;

        .nav__text {
          border-bottom: 2px solid #143C96;
        }
      }

      &:hover {
        background: none;
        background-color: transparent;
      }
      &:focus {
        background: none;
        background-color: transparent;
      }
      &:visited {
        background: none;
        background-color: transparent;
      }
    }

    &.nav__item_bordered {
      padding-left: 0 !important;
      padding-right: 0 !important;

      &:last-child {
        .nav__vline {
          margin-right: 0;
        }
      }
    }
  }
}




