@media (min-width: 768px) and (max-width: 1024px) {
  .nav__main-header_container {
    ul {
      margin: 0 -15px;

      > .nav__item {
        padding-right: 5px;
        padding-left: 5px;
      }
    }
  }
}