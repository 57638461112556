form.forgot-password {
  .form-group {
    button {
      height: 50px;
      line-height: 50px;
      background: none;
      border: none;
      background-color: #DCE84C;
      box-shadow: 0 1px 0 0 #A9B811;
      border-radius: 100px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: #143C96;
      padding: 0 100px;
      -webkit-font-smoothing: antialiased;
      min-width: 120px;
      &:disabled {
        opacity: 0.7 !important;
      }
    }
  }
}