.home-promo {
  .promo-category-box {
    position: relative;
    height: 270px;
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 100% auto;
    margin-bottom: 60px;

    .button-redirect{
      position: absolute;
      font-size: 24px;
      color: #143C96;
      font-weight: bold;
      background-color: #FFFFFF;
      border-radius: 100%;
      padding: 12px;
      right: 20px;
      bottom: 20px;
    }

    .promo-category-box__text {
      position: absolute;
      top: 20px;
      left: 10px;

      h3 {
        color: #153D97;
        font-size: 25px;
        line-height: 30px;
        background-color: #FFFFFF;
        padding: 10px 20px;
      }
    }

    .promo-category-box__image {
      position: absolute;
      left: 35px;
      bottom: -40px;
    }
  }
}
