.Select-value {
  span.Select-value-label {
    font-family: 'Roboto', sans-serif;
    color: gray !important;
    font-weight: 300;
  }
}

.address-form {
  label.address-form__default {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #4E5A61;
    margin-bottom: 10px;
  }
}