.privacy-policy, .return-policy {
  ul {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 30px;
    list-style: disc inside;
    padding-left: 25px;
  }
  strong {
    font-weight: 900;
    font-size: 16px;
  }
  span.privacy-policy__send-email {
    color: #DCE84C;
  }
}