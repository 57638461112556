.add-to-cart-button {
  outline: none;

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.add-to-cart-button--style-button {
  display: inline-block;
  cursor: pointer;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 700;
  font-size: 14px;
  color: #143C96;
  border: none;
  background: #DCE84C;
  box-shadow: 0 1px 0 0 #A9B811;
  border-radius: 100px;
  padding: 0 40px;
  line-height: 50px;
  height: 50px;
  margin-bottom: 20px;
  margin-left: 10px;

  &.add-to-cart-button--size-small {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 35px;
    line-height: 35px;
    border-radius: 35px !important;
  }

  .add-to-cart-button__icon {
    position: relative;
    top: 1px;
  }

  .add-to-cart-button__text {
    text-transform: uppercase;
  }
}

.add-to-cart-button--style-link {
  border: none;
  background-color: transparent;
  border-radius: 0;
  color: #143C96;
}

.add-to-cart-button--disabled {
  opacity: .5;
}
